import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button, 
  Chip, 
  CircularProgress, 
  IconButton, 
  Link, 
  Menu, 
  MenuItem, 
  Skeleton, 
  Tooltip, 
  Typography,

} from '@mui/material';

import useTablePaginationInit from '../../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import NA from '../../../../components/utils/NA';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { 
  CreateVerificationCompanyBankAccount,
  GetBankAccountTypes, 
  GetBanks,
  GetCompanyBankAccounts,
  ToggleDefaultCompanyBankAccount
} from '../../../../redux/actions/pay/companyBankAccountsActions';

import BankAccountModal from './BankAccountModal';

import global from "../../../../global.scss";
import "./styles.scss";

const PaymentMethodsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [bankAccountsModalOpen, setBankAccountsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [companyBankAccountsList, setCompanyBankAccountsList] = useState([]);
  const [companyBankAccountsTotal, setCompanyBankAccountsTotal] = useState(0);

  const [bankAccountsMenuOpen, setBankAccountsMenuOpen] = useState(false);
  const [bankAccountsMenuAnchor, setBankAccountsMenuAnchor] = useState(0);

  const [verifyId, setVerifyId] = useState(null);
  const [verifying, setVerifying] = useState(false);

  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  useEffect(() => {
    dispatch(GetBankAccountTypes(companyId, 1, 50, setLoading2));
    dispatch(GetBanks(companyId, 1, 500, setLoading2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(GetCompanyBankAccounts(companyId, page+1, rowsPerPage, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    try {
      formatCompanyBankAccountsList(state.companyBankAccountsList.records || []);
      setCompanyBankAccountsTotal(state.companyBankAccountsList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatCompanyBankAccountsList = (list) => {
    setCompanyBankAccountsList(list);
  };

  const handleDefaultChange = (id) => {
    dispatch(ToggleDefaultCompanyBankAccount(companyId, update.id, setLoading)).then(() => {
      dispatch(GetCompanyBankAccounts(companyId, page+1, rowsPerPage, setLoading));
    })
  };

  const handleVerifyBankAccount = (id) => {
    setVerifyId(id);
    let obj = {
      "bank_account_id": id
    };
    dispatch(CreateVerificationCompanyBankAccount(companyId, obj, setVerifying)).then(() => {
      dispatch(GetCompanyBankAccounts(companyId, page+1, rowsPerPage, setVerifying)).then(() => {
        setVerifyId(null);
      });
    });
  }

  return (
    <Box>
      
      <BankAccountModal
        open={bankAccountsModalOpen}
        setOpen={setBankAccountsModalOpen}
        title={modalTitle}
        update={update}
        page={page}
        rowsPerPage={rowsPerPage} 
        />

      <Box 
        className="pmtmethod__top">
        <Box>
          <Typography
            variant='h6'>
            Bank Account
          </Typography>
          {/* <Typography color={"grey"}>Free</Typography> */}
        </Box>

        {
          loading 
          ?
          <Skeleton 
            variant='rectangular'
            sx={{ height: "90px", borderRadius: "16px" }}
            />
          :
          companyBankAccountsList.length === 0
          ?
          <Box
            className="flexCenterCenterRow"
            height={"90px"}>
            <NA 
              label={"No bank account added"}/>
          </Box>
          :
            companyBankAccountsList.map((bank, bankIndex) => (
              <Box
                className="pmtmethod__top-card flexCenter_Row"
                gap={"20px"}>
                <AccountBalanceOutlinedIcon 
                  fontSize="large"
                  />
                <Box
                  sx={{ marginRight: "auto" }}>
                  <Typography variant='subtitle2'>
                    {bank?.account_type_id?.type || <NA />}
                    &nbsp;-&nbsp;
                    {bank.account_number?.slice(-5) || <NA />}</Typography>
                  {/* <Typography color={"grey"}>Free</Typography> */}
                  <Box
                    className="flexCenter_Row"
                    gap={"10px"}>
                    {
                      bank.is_default === true
                      &&
                      <Chip
                        color='warning'
                        size='small'
                        label={'Primary'} 
                        />
                    }
                    {
                       bank.verification_pending === false && bank.verified === false
                      &&
                      <Chip
                        color='error'
                        size='small'
                        label={'Unverified'} 
                        />
                    }
                  </Box>
                </Box>
                {
                  bank.verification_pending === true
                  ?
                  <Tooltip>
                    <Chip
                      color='warning'
                      label={"Verification pending"} 
                      // clickable={true}
                      // onClick={() => {
                        
                      // }}
                      />
                  </Tooltip>
                  :
                  <Box
                    className="flexCenter_Row"
                    gap={"5px"}>
                    {
                      bank.verified === false
                      &&
                        (bank.id === verifyId && verifying
                        ?
                        <CircularProgress 
                          size={28}
                          />
                        :
                        <Chip
                          color='info'
                          label={"Verify"} 
                          clickable={true}
                          onClick={() => {
                            handleVerifyBankAccount(bank.id);
                          }}
                          />)
                    }
                    <IconButton
                      onClick={(e) => {
                        setBankAccountsMenuAnchor(e.currentTarget);
                        setBankAccountsMenuOpen(true);
                        // setDeleteId(row.id);
                        setUpdate(bank);
                        setModalTitle(bank.account_number || "");
                      }}>
                      <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                    </IconButton>
                  </Box>
                }
              </Box>
            ))
        }


        <Link
          onClick={() => {
            setUpdate({
              defaultMode: 1
            });
            setModalTitle("Add Bank account")
            setBankAccountsModalOpen(!bankAccountsModalOpen);
          }}>
          + Add another Bank Account
        </Link>
      </Box>

      {/* <Box
        className="pmtmethod__bottom">
        <Box>
          <Typography
            variant='h6'>
            Card
          </Typography>
          <Typography color={"grey"}>2.9% fee</Typography>
        </Box>
        <Typography textAlign={"center"}>Defer payments and earn rewards.</Typography>

        <Link>+ Add card</Link>
      </Box> */}


      <Menu
        open={bankAccountsMenuOpen}
        anchorEl={bankAccountsMenuAnchor}
        onClose={() => setBankAccountsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setBankAccountsMenuOpen(false);
            setBankAccountsModalOpen(!bankAccountsModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
        {
          update?.is_default === false 
          &&
          <MenuItem
            onClick={() => {
              setBankAccountsMenuOpen(false);
              handleDefaultChange();
            }}>
            <Box
              className="flexCenter_Row"
              gap={"10px"}>
              <TaskAltOutlinedIcon />
              Make Primary
            </Box>
          </MenuItem>
        }
      </Menu>

    </Box>
  )
}

export default PaymentMethodsPage;