import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as actionTypes from "../../redux/actionTypes";

const ErrorHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector(state => state.other);

  useEffect(() => {
    try {
      console.log(state);
      if(state.errors !== null) errorParser(dispatch, state.errors);
      if(state.crashes !== null) crashParser(dispatch, state.crashes);
    } catch(err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // ------------------- testing functions -------------------
  const errorParser = (dispatch, data) => {
    // for strings
    if(typeof data === 'string'){
      toast.error(data);
    };

    // for objects
    if("message" in data){
      toast.error(data.message);
    } else {
      // toast.error("Something went wrong");
    }
    
    // errors will be resolved at first instance.
    dispatch({ type: actionTypes.CLEAR_ERRORS });
  }

  const crashParser = (dispatch, data) => { 
    // for strings
    if(typeof(data) === 'string'){
      // for request canceller function
      if(data === "REQUEST_CANCELLED"){

      // for expiry of refresh token
      } else if (data === "Internal server error or Refresh Token might have expired. Please login again") {
        toast.error("Session expired, Please login again");
      } else {
        toast.error("Something went wrong");
      }
    };

    dispatch({ type: actionTypes.CLEAR_CRASHES });

    if("data" in data.response){
      if ("message" in data.response.data){
        toast.error(data.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
      return;
    };

    // for objects
    if("message" in data){
      // for request canceller function
      if(data.message === "REQUEST_CANCELLED"){

      // for expiry of refresh token
      } else if (data.message === "Internal server error or Refresh Token might have expired. Please login again") {
        // toast.error("Session expired, Please login again");
      } else { 
        // toast.error(data.message);
      }
    }else if("detail" in data){
      
    }else{
      // toast.error("Something went wrong");
    };
    
  }

}

export default ErrorHandler;