import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Modal, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  TextField, 
  ToggleButton, 
  ToggleButtonGroup, 
  Tooltip, 
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LanguageIcon from '@mui/icons-material/Language';
import LoopIcon from '@mui/icons-material/Loop';

import useCurrencyService from '../../../components/hooks/useCurrencyService';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import AmountTextField from '../../../components/textFields/AmountTextField';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../components/buttons/Button';
import NameAvatar from '../../../components/avatars/Avatar';
import NA from '../../../components/utils/NA';

import { 
  CreateBill, 
  UpdateBill 
} from '../../../redux/actions/pay/billsActions';
import { 
  GetChartOfAccountsForExpenses 
} from '../../../redux/actions/accounting/chartOfAccountsActions';
import { 
  GetProducts 
} from '../../../redux/actions/accounting/productsActions';
import { 
  UploadFiles 
} from '../../../redux/actions/miscellaneousActions';

import global from "../../../global.scss";
import "./styles.scss";
import { GetDetailedRates } from '../../../redux/actions/international/ratesActions';

const LineItem = {
  ProductId: null, 
  ExpensesAccountId: null, 
  Description: null,
  Quantity: null, 
  Amount: null
};

const RecurringPaymentDuration = [
  { value: "UNTIL_DECIDE_CANCEL", label: "Until I decide to cancel" },
  { value: "UNTIL_END_DATE", label: "Until an end date" },
  { value: "UNTIL_NUM_OF_PAYMENT", label: "Until number of payments made" }
];

const Data = {
  PaymentFrequency: 1,
  RecurringPaymentDuration: "UNTIL_DECIDE_CANCEL",
  Vendor: null,
  Currency: "USD",
  Amount: "",
  BaseCurrency: "USD",
  BaseAmount: "", 
  InvoiceDate: dayjs(),
  DueDate: dayjs(),
  FirstPmtDeliveryDate: dayjs(),
  RecurringEndDate: dayjs(),
  LastPmtAmount: "",
  NumberOfPmts: ""
};

const CreateBillModal = ({
  open, 
  setOpen,
  title,
  onMethod,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const accounting = useSelector(state => state.accounting);
  const companyId = useSelectedCompany();
  const { 
    handleShowAmount, 
    handleCalculateAmount,
    handleEditAmount 
  } = useCurrencyService();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingPay, setLoadingPay] = useState(false);
  const [mode, setMode] = useState(-1);

  const [paymentFrequenciesList, setPaymentFrequenciesList] = useState([]);
  const [expensesAccountsList, setExpensesAccountsList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [isInternationalVendor, setIsInternationalVendor] = useState(false);
  
  const [lineItemsList, setLineItemsList] = useState([LineItem]);
  const [lineItemsError, setLineItemsError] = useState(false);

  const [showBillDetails, setShowBillDetails] = useState(false);
  
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const [fixedSide, setFixedSide] = useState("buy");
  const [rateFetching, setRateFetching] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setShowBillDetails(false);
    setLineItemsList([LineItem]);
    setLoading(false);
    setLoading2(false);
    setFormData(Data);
    setIsInternationalVendor(false);
    setRateFetching(false);
    setLineItemsError(false);
    setSelectedFiles([]);
    setPreviews([]);
    setMode(-1);
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  useEffect(() => {
    try {
      if(open){
        dispatch(GetProducts(companyId, 1, 1000, 1, "&buy_this=true", setLoading));
        dispatch(GetChartOfAccountsForExpenses(companyId, 1, 1000, "", setLoading));

        if(update?.defaultMode === 1){
          // mode = 1 - create bill
          setMode(1);
          setSelectedFiles([]);
          setPreviews([]);

          if("defaultVendor" in update){
            setFormData({
              ...Data,
              Vendor: update?.defaultVendor
            });
            setIsInternationalVendor(update?.isVendorInternational);
            fillDefaultLineItemAmount(update?.total_amount);
            setShowBillDetails(false);
          }else{
            setFormData(Data);
          }
        }else if(update?.defaultMode === 2){
          // mode = 2 - edit bill
          setMode(2);

          setFormData({
            ...formData,
            PaymentFrequency: update?.ref_payment_frequency_id?.id,
            // RecurringPaymentDuration: "UNTIL_DECIDE_CANCEL",
            Vendor: update?.ref_vendor_id?.vendor_id,
            Amount: update?.total_amount,
            InvoiceNumber: update?.invoice_number
            // InvoiceDate: dayjs(),
            // DueDate: dayjs(),
            // FirstPmtDeliveryDate: dayjs(),
            // RecurringEndDate: dayjs(),
            // LastPmtAmount: "",
            // NumberOfPmts: ""
          });

          setPreviews(update?.bill_attachments || []);

          fillDefaultLineItemAmount(update?.total_amount);
          setShowBillDetails(true);
        }else{
          toast.error("Something went wrong!");
          handleClose();
        }
      }else{
        setMode(-1);
      }
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatVendorsList(state.vendorsList.records || []);
      formatPaymentFrequenciesList(state.paymentFrequenciesList || []);
    } catch (err) {}
  }, [state]);

  useEffect(() => {
    try {
      setProductsList(accounting.productsList.records || [])
      formatExpensesAccountsList(accounting.chartOfAccountsForExpensesList.records || []);
    } catch (err) {}
  }, [accounting]);

  // --------------- list formatter --------------
  const formatVendorsList = (list) => {
    setVendorsList(list.filter(i => 
      i.is_active === true && 
      (i?.associated_vendor_bank_account.length !== 0 || 
        i?.is_international === true
      )));
  };

  const formatExpensesAccountsList = (list) => {
    let temp = list.map((i) => {
      return {
        "id": i.id,
        "account_name": i.account_name,
        "account_number": i.account_number,
      }
    });
    setExpensesAccountsList(temp);
  };

  const formatPaymentFrequenciesList = (list) => {
    setPaymentFrequenciesList(list.filter(i => i.is_active === true));
  };

  // --------------- handle change ---------------
  const handleChange = (e) => {
    if(e.target.name === "Amount"){
      let lineItemsTotal = 0;
      lineItemsList.forEach(i => lineItemsTotal += parseFloat(i.Amount+"") || 0);
      if(lineItemsTotal+"" !== e.target.value){
        setLineItemsError(true);
      }else{
        setLineItemsError(false);
      }
    }

    if(e.target.name === "PaymentFrequency") {
      // reset recurringPaymentDuration
      setFormData({ ...formData, [e.target.name]: e.target.value, RecurringPaymentDuration: null });
    }else if (e.target.name === "Vendor"){
      let vendorTemp = vendorsList.find(i => i.vendor_id === e.target.value);
      if(vendorTemp.is_international === true){
        let temp = {
          Currency: vendorTemp?.associated_international_vendor[0].currency,
          BeneficiaryCountry: vendorTemp?.associated_international_vendor[0].beneficiary_country,
          BankCountry: vendorTemp?.associated_international_vendor[0].bank_country,
        };
      //   {
      //     "beneficiary_country": "IN",
      //     "bank_country": "IN",
      //     "currency": "INR",
      //     "payment_types": "[\"regular\", \"priority\"]"
      // }
        setFormData({ ...formData, [e.target.name]: e.target.value, ...temp });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
      setIsInternationalVendor(vendorTemp.is_international);

    } else if (e.target.name === "Amount") {
      if(isInternationalVendor) handleGetConversionRates(e.target.value, "buy");
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else if (e.target.name === "BaseAmount") {
      if(isInternationalVendor) handleGetConversionRates(e.target.value, "sell");
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }else{
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    console.log({ ...formData, [e.target.name]: e.target.value })
  };

  async function handleLineItemChange(index, e) {
    let list = [ ...lineItemsList ];
    let obj = list[index];

    if(e.target.name === "ProductId"){
      obj = { ...obj, 
        [e.target.name]: e.target.value,
        ExpensesAccountId: e.target.value.expense_account.id,
        AccountName: e.target.value.expense_account.account_name,
        AccountNumber: e.target.value.expense_account.account_number,
        ProductName: e.target.value.name,
        Quantity: 1,
        Amount: handleEditAmount(
          formData.Currency,
          e.target.value.associated_selling_accounting_product[0]?.selling_price
        ) 
      };
    } else if(e.target.name === "ExpensesAccountId"){
      let selectedExpensesAccount = expensesAccountsList.find(i => i.id === e.target.value);
      obj = { ...obj, 
        [e.target.name]: e.target.value,
        AccountName: selectedExpensesAccount?.account_name,
        AccountNumber: selectedExpensesAccount?.account_number,
      };
    } else {
      obj = { ...obj, [e.target.name]: e.target.value };
    }

    list[index] = obj;
    setLineItemsList(list);

    // add all line items and make total amount equal to it
    let total = 0;
    list.forEach((item) => total += handleCalculateAmount(formData.Currency, item.Amount || 0));
    setFormData({ ...formData, Amount: handleShowAmount(formData.Currency, total || 0)});

    setLineItemsError(false);
  }

  // -------------- line item --------------------
  const addLineItem = () => {
    let obj = {
      ...LineItem,
      Amount: 0,
      Description: ""
    }
    setLineItemsList([
      ...lineItemsList, 
      obj
    ]);
  };

  const removeLineItem = (index) => {
    let list = [ ...lineItemsList ];
    setLineItemsList(list.filter((item, i) => i !== index));
    reCalculateAmount(list.filter((item, i) => i !== index));
  };

  // --------------- handle create ---------------
  const handleSubmit = async () => {
    try {
      if(mode === 1){
        // mode = 1 -> create bill
        let temp_attachment_list = selectedFiles.length > 0 ? await handleUploadImages() : [];

        let obj = {
          ...handleCreateResponse(),
          attachment_list: temp_attachment_list,
          is_bill_with_payment: false
        };
  
        dispatch(CreateBill(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2) {
        // mode = 2 -> edit bill
        let obj = {
          ...handleCreateResponse(),
          is_bill_with_payment: false
        };
    
        console.log(obj, formData, lineItemsList);
  
        dispatch(UpdateBill(companyId, update?.bill_id, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });

      } else {
        throw new Error();
      };
    } catch(err) {
      console.log("BILL_CRT_1", err);
      toast.error("Something went wrong!");
    }
  };

  const handlePay = async () => {
    // mode = 1 -> create bill
    let temp_attachment_list = selectedFiles.length > 0 ? await handleUploadImages(setLoadingPay) : [];

    let obj = {
      ...handleCreateResponse(),
      attachment_list: temp_attachment_list
    };

    dispatch(CreateBill(companyId, obj, setLoadingPay)).then(({res, statusCode}) => {
      if(statusCode === 201){
        onMethod({
          ...res.data,
          vendor_name: handleCreateResponse().vendor_name,
          vendor_bank_account: handleCreateResponse().vendor_bank_account,
          due_date: handleCreateResponse().due_date,
          invoice_date: handleCreateResponse().bill_date,
          invoice_number: handleCreateResponse().invoice_number,
          defaultMode: 1
        });
        handleClose();
      }
    });
  };

  function handleCreateResponse () {
    let vendorTemp = vendorsList.find(i => i.vendor_id === formData.Vendor);

    return {
      currency: formData.Currency,
      total_amount: handleCalculateAmount(formData.Currency, formData.Amount || 0),
      base_currency: isInternationalVendor === true ? formData.BaseCurrency : formData.Currency,
      base_amount: isInternationalVendor === true 
        ? handleCalculateAmount(formData.BaseCurrency, formData.BaseAmount || 0)
        : handleCalculateAmount(formData.Currency, formData.Amount || 0),
      fixed_side: fixedSide,
      ref_vendor_id: formData.Vendor,
      vendor_name: vendorTemp?.name,
      vendor_bank_account: vendorTemp?.associated_vendor_bank_account[0]?.account_number?.slice(-4),
      invoice_number: formData.InvoiceNumber,
      note_to_self: formData.Description,
      bill_date: dayjs(formData.InvoiceDate).format('YYYY-MM-DD'),
      due_date: dayjs(formData.DueDate).format('YYYY-MM-DD'),
      lines: showBillDetails === true 
        ? lineItemsList.map((item, index) => { 
            return { 
              line_number: index,
              ref_product_id: item?.ProductId?.product_id || null,
              product_name: item?.ProductName || null,
              account_name: item?.AccountName || null,
              account_number: item?.AccountNumber || null,
              description: item.Description || "NA",
              quantity: item.Quantity || 0,
              amount: handleCalculateAmount(formData.Currency, item.Amount || 0),
            }})
        : [],
      ref_payment_frequency_id: formData.PaymentFrequency,
      recurring_duration: formData.RecurringPaymentDuration,
      recurring_end_date: dayjs(formData.RecurringEndDate).format('YYYY-MM-DD'),
      recurring_num_of_pmts: formData.NumberOfPmts,
      recurring_first_pmt_delivery_date: dayjs(formData.FirstPmtDeliveryDate).format('YYYY-MM-DD'),
      // last_pmt_amount: formData.LastPmtAmount
    }
  };

  // -------------------------- miscellanoues ---------------------------
  const fillDefaultLineItemAmount = (amount) => {
    let arr = [ ...lineItemsList ];
    arr[0].Amount = amount ? amount : formData.Amount;
    setLineItemsList(arr);
    setLineItemsError(false);
  };

  const reCalculateAmount = (list) => {
    let lineItemsTotal = 0;
    if(list){
      list.forEach(i => lineItemsTotal += parseFloat(i.Amount+"") || 0);
    }else{
      lineItemsList.forEach(i => lineItemsTotal += parseFloat(i.Amount+"") || 0);
    };
    setFormData({
      ...formData,
      Amount: lineItemsTotal
    });
  };

  function disableWeekends(date) {
    let currentDay = dayjs(date).day();
    if(currentDay >= 1 && currentDay <= 5 ){
      return false;
    } else {
      return true;
    };
  };

  const handleUploadImages = async (customLoading) => {
    let path = [];

    for(let i = 0 ; i < selectedFiles.length ; i++){
      const image = new FormData();
      image.append("new_file", selectedFiles[i]);
      await dispatch(UploadFiles(image, customLoading ? customLoading : setLoading)).then(({res, statusCode}) => {
        if(statusCode === 201) {
          path.push({
            path: res.data.file_path,
            type: selectedFiles[i].type
          })
        } 
      });
    };

    return path;
  };

  useEffect(() => {
    let previewTemp = [];

    for(let i = 0 ; i < selectedFiles.length ; i++){
      const objectUrl = URL.createObjectURL(selectedFiles[i])
      previewTemp.push(objectUrl);
    };
    setPreviews(previewTemp);
  }, [selectedFiles]);

  const onSelectFile = e => {
    const max_file_select = 1

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFiles([]);
      return;
    }  

    let temp = [];

    let index = 0;
    for(let i = 0 ; i < e.target.files.length ; i++){
      if(index > max_file_select) break;
      index++;
      temp.push(e.target.files[i]);
    };

    temp = [ ...selectedFiles, ...temp ];

    setSelectedFiles(temp.slice(0, max_file_select));
  };

  function handleGetConversionRates (amount, fixed_side) {
    if(amount && amount.length > 0){
      let query = "";
      query += `&buy_currency=${formData.Currency}`;
      query += `&sell_currency=${formData.BaseCurrency}`;
      query += `&fixed_side=${fixed_side}`;
      query += `&amount=${amount.replace(/[^0-9.]/g, '')}`;
  
      setRateFetching(true);
      dispatch(GetDetailedRates(companyId, query, setLoading2)).then(({ res, statusCode }) => {
        if(statusCode === 200){
          setRateFetching(false);
          console.log(res, fixedSide);
  
          if(fixedSide === "buy") {
            setFormData({
              ...formData,
              Amount: handleEditAmount(formData.Currency, handleCalculateAmount(formData.Currency, res.data.clientBuyAmount)),
              BaseAmount: handleEditAmount(formData.BaseCurrency, handleCalculateAmount(formData.BaseCurrency, res.data.clientSellAmount)) 
            });
          } else {
            setFormData({
              ...formData,
              Amount: handleEditAmount(formData.Currency, handleCalculateAmount(formData.Currency, res.data.clientBuyAmount)),
              BaseAmount: handleEditAmount(formData.BaseCurrency, handleCalculateAmount(formData.BaseCurrency, res.data.clientSellAmount)) 
            });
          }
        }
      }); 
    } else {
      setRateFetching(false);
    }
  };

//   {
//     "settlementCutOffTime": "2024-12-03T16:30:00Z",
//     "currencyPair": "USDINR",
//     "clientBuyCurrency": "INR",
//     "clientSellCurrency": "USD",
//     "clientBuyAmount": "1885.01",
//     "clientSellAmount": "23.0",
//     "fixedSide": "sell",
//     "clientRate": "81.9571",
//     "partnerRate": "82.369",
//     "coreRate": "82.7829",
//     "depositRequired": false,
//     "depositAmount": "0.0",
//     "depositCurrency": "USD",
//     "midMarketRate": "83.2826"
// }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box
          className={`fullpage__modal animate__animated animate__fadeIn`}>

          <ModalTopBar 
            title={title}
            onBack={handleClose}
            />

          <Container 
            maxWidth="xl">    
            <Box
              className="flexCenter_Row"
              gap={"20px"}>
              {/* bill attachment */}
              <Box
                className="flexCenterCenterColumn"
                sx={{
                  minWidth: "500px",
                  height: "calc(100vh - 120px)",
                  borderRadius: "12px",
                  border: "dashed 1px lightgrey"
                }}>
                {
                  selectedFiles.length < 4 &&  
                    <Box
                      className="flexCenter_Row"
                      gap={"20px"}>
                      {
                        previews.map((image, imageIndex) => (
                          <Box
                            key={imageIndex}
                            sx={{ position: "relative" }}>
                            <Box
                              color='white'
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "10px",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "-15px",
                                right: "-15px",
                                backgroundColor: "lightgrey",
                                cursor: "pointer",
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                              }}
                              onClick={() => {
                                let temp = selectedFiles.filter((item, index) => index !== imageIndex);
                                setSelectedFiles(temp);
                              }}>
                              <CloseRoundedIcon 
                                fontSize='small'
                                sx={{ color: "black" }}/>
                            </Box>
                            {
                              mode === 1
                              ?
                                selectedFiles[imageIndex]?.type === "application/pdf"
                                ?
                                <iframe
                                  src={image}
                                  title="PDF Viewer"
                                  width="470px"
                                  height="700px"
                                ></iframe>
                                :
                                <img 
                                  key={imageIndex}
                                  src={image}
                                  alt='expense-receipt'
                                  className='expense__receipt'
                                  style={{ width: "300px" }}
                                  /> 
                              :
                                image?.attachment_type === "application/pdf"
                                ?
                                <iframe
                                  src={image?.attachment_path}
                                  title="PDF Viewer"
                                  width="470px"
                                  height="700px"
                                ></iframe>
                                :
                                <img 
                                  key={imageIndex}
                                  src={image?.attachment_path}
                                  alt='expense-receipt'
                                  className='expense__receipt'
                                  style={{ width: "300px" }}
                                  /> 

                            }
                          </Box>
                        ))
                      }
                    </Box>
                }
                {
                  mode === 1
                  ?
                    selectedFiles.length === 0
                    &&
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      disabled={selectedFiles.length > 2}
                      tabIndex={-1}>
                      {
                        selectedFiles.length === 0
                        ?
                          "Upload invoice"
                        :
                          "Add more invoice"
                      }
                      <VisuallyHiddenInput 
                        type="file" 
                        name="myImage"
                        multiple
                        accept=".png, .jpeg, .jpg, .pdf" 
                        onChange={onSelectFile} />
                    </Button>
                  : 
                    previews.length === 0
                    &&
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      disabled={previews.length > 2}
                      tabIndex={-1}>
                      {
                        previews.length === 0
                        ?
                          "Upload invoice"
                        :
                          "Add more invoice"
                      }
                      <VisuallyHiddenInput 
                        type="file" 
                        name="myImage"
                        multiple
                        accept=".png, .jpeg, .jpg, .pdf" 
                        onChange={onSelectFile} />
                    </Button>
                }
              </Box>
              {/* bill */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "calc(100vh - 120px)",
                  overflow: "scroll"
                }}>
                <Box 
                  display={"flex"} 
                  flexDirection={"column"} 
                  gap={"15px"}
                  pt={"20px"}>
                  <TextField 
                    select
                    label="Vendor"
                    name="Vendor"
                    size='small'
                    value={formData.Vendor || ""}
                    onChange={handleChange}>
                    {
                      vendorsList.length === 0
                      ? <MenuItem disabled><i>No vendor found</i></MenuItem>
                      : vendorsList.map((item, index) => (
                          <MenuItem key={index}
                            value={item.vendor_id}>
                            <Box
                              className="flexCenter_Row"
                              gap={"10px"}>
                              <NameAvatar
                                color={item.color || null}>
                                {item.name || "NA"}
                              </NameAvatar>
                              <Typography>
                                {item.name || "NA"}
                              </Typography>
                              {
                                item?.is_international === true
                                &&
                                <Tooltip
                                  title={"International vendor"}>
                                  <LanguageIcon 
                                    sx={{ color: "lightgray" }}
                                    fontSize='small'/>
                                </Tooltip>
                              }
                            </Box>
                          </MenuItem>
                        ))
                    }
                  </TextField>    

                  {
                    isInternationalVendor === true
                    &&
                    <Box
                      className="flexCenterCenterRow">
                      <FormControl component="fieldset" sx={{ display: "flex", gap: 1 }}>
                        <FormLabel component="legend" sx={{ margin:"auto", mb: 0.5 }}>
                          Fixed currency
                        </FormLabel>
                        <ToggleButtonGroup
                          color="primary"
                          value={fixedSide}
                          exclusive
                          onChange={(e) => setFixedSide(e.target.value)}
                          aria-label="Platform"
                        >
                          <ToggleButton
                            value={"sell"}
                            sx={{ width: "120px", padding: "3px", minHeight: "37.5px"  }}>
                            {formData.BaseCurrency}
                          </ToggleButton>
                          <ToggleButton 
                            value={"buy"}
                            sx={{ width: "120px", padding: "3px", minHeight: "37.5px"  }}>
                            {formData.Currency}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                    </Box>
                  }

                  
                  {
                    isInternationalVendor === true
                    ?
                    <>
                      <Box 
                        className="flexFSSBRow"
                        gap="15px">
                        <AmountTextField 
                          currencyName="BaseCurrency"
                          currency={formData.BaseCurrency}
                          onCurrencyChange={handleChange}
                          disableCurrencySelector={true}
                          label="Amount in USD"
                          fullWidth
                          name="BaseAmount"
                          size='small'
                          loading={rateFetching && fixedSide === "buy" ? true : false}
                          disabled={(showBillDetails && formData.PaymentFrequency === 1) || (fixedSide === "buy")}
                          value={formData.BaseAmount}
                          onChange={handleChange}
                          helperText={formData.PaymentFrequency !== 1 
                            && "Bill amount updated to match the sum or your line Items"}
                          />
                        <AmountTextField 
                          currencyName="Currency"
                          currency={formData.Currency}
                          onCurrencyChange={handleChange}
                          disableCurrencySelector={true}
                          label="Bill amount in vendor currency"
                          fullWidth
                          name="Amount"
                          size='small'
                          loading={rateFetching && fixedSide === "sell" ? true : false}
                          disabled={(showBillDetails && formData.PaymentFrequency === 1) || (fixedSide === "sell")}
                          value={formData.Amount}
                          onChange={handleChange}
                          helperText={formData.PaymentFrequency !== 1 
                            && "Bill amount updated to match the sum or your line Items"}
                          />
                      </Box>
                      <Box 
                        className="flexFSSBRow"
                        gap="15px">
                        {
                          (showBillDetails && mode === 1)
                          &&
                          <TextField 
                            select
                            label="Payment frequency"
                            name="PaymentFrequency"
                            size='small'
                            value={formData.PaymentFrequency}
                            onChange={handleChange}
                            helperText={
                              <Typography
                                variant='caption'
                                color={"grey"}>
                                Line items are excluded when scheduling a recurring payment
                              </Typography>
                            }
                            fullWidth>
                            {
                              paymentFrequenciesList.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                  <Box className="flexCenter_Row" gap={"10px"}>
                                    <Typography>{item.pmt_frequency}</Typography>
                                    {
                                      item.is_recurring === true
                                      &&
                                      <Chip
                                        size='small'
                                        label={
                                          <Box 
                                            className="flexCenterFERow"
                                            color={"grey"}>
                                            <LoopIcon fontSize='small'/>
                                            <Typography variant='caption' color={"grey"}>
                                              Recurring
                                            </Typography>
                                          </Box>
                                        } 
                                        />
                                    }
                                  </Box>
                                </MenuItem>
                              ))
                            }
                          </TextField>
                        }
                      </Box>
                    </>
                    :
                    <Box 
                      className="flexFSSBRow"
                      gap="15px">
                      <AmountTextField 
                        currencyName="Currency"
                        currency={formData.Currency}
                        onCurrencyChange={handleChange}
                        label="Bill amount"
                        fullWidth
                        name="Amount"
                        size='small'
                        disabled={showBillDetails && formData.PaymentFrequency === 1}
                        value={formData.Amount}
                        onChange={handleChange}
                        helperText={formData.PaymentFrequency !== 1 
                          && "Bill amount updated to match the sum or your line Items"}
                        />
                      {
                        (showBillDetails && mode === 1)
                        &&
                        <TextField 
                          select
                          label="Payment frequency"
                          name="PaymentFrequency"
                          size='small'
                          value={formData.PaymentFrequency}
                          onChange={handleChange}
                          helperText={
                            <Typography
                              variant='caption'
                              color={"grey"}>
                              Line items are excluded when scheduling a recurring payment
                            </Typography>
                          }
                          fullWidth>
                          {
                            paymentFrequenciesList.map((item, index) => (
                              <MenuItem key={index} value={item.id}>
                                <Box className="flexCenter_Row" gap={"10px"}>
                                  <Typography>{item.pmt_frequency}</Typography>
                                  {
                                    item.is_recurring === true
                                    &&
                                    <Chip
                                      size='small'
                                      label={
                                        <Box 
                                          className="flexCenterFERow"
                                          color={"grey"}>
                                          <LoopIcon fontSize='small'/>
                                          <Typography variant='caption' color={"grey"}>
                                            Recurring
                                          </Typography>
                                        </Box>
                                      } 
                                      />
                                  }
                                </Box>
                              </MenuItem>
                            ))
                          }
                        </TextField>
                      }
                    </Box>
                  }
                  {
                    showBillDetails
                    &&
                    <Box
                      display={"flex"} 
                      flexDirection={"column"} 
                      gap={"15px"}>
                      {
                        formData.PaymentFrequency === 1
                        ?
                        <Box 
                          className="flexCenter_Row"
                          gap="15px">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              slotProps={{ 
                                textField: { 
                                  size: 'small',
                                  fullWidth: true,
                                } 
                              }}
                              shouldDisableDate={disableWeekends}
                              label="Invoice date (optional)" 
                              name="InvoiceDate"
                              value={formData.InvoiceDate}
                              minDate={dayjs()}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "InvoiceDate",
                                    value: e
                                  }
                                });
                                if(formData.DueDate < e){
                                  handleChange({
                                    target: {
                                      name: "DueDate",
                                      value: e
                                    }
                                  });
                                }
                              }}/>
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              slotProps={{ 
                                textField: { 
                                  size: 'small',
                                  fullWidth: true
                                } 
                              }}
                              shouldDisableDate={disableWeekends}
                              label="Due date" 
                              name="DueDate"
                              minDate={formData.InvoiceDate}
                              value={formData.DueDate}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "DueDate",
                                    value: e
                                  }
                                });
                              }}/>
                          </LocalizationProvider>
                        </Box>
                        : 
                        <Box 
                          className="flexCenter_Row"
                          gap="15px">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              slotProps={{ 
                                textField: { 
                                  size: 'small',
                                  fullWidth: true
                                } 
                              }}
                              shouldDisableDate={disableWeekends}
                              label="First payment delivery date"
                              name="FirstPmtDeliveryDate"
                              minDate={dayjs()}
                              value={formData.FirstPmtDeliveryDate}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "FirstPmtDeliveryDate",
                                    value: e
                                  }
                                });
                              }}/>
                          </LocalizationProvider>
                          <TextField 
                            select
                            size='small'
                            label="Recurring payment duration"
                            name="RecurringPaymentDuration"
                            value={formData.RecurringPaymentDuration}
                            onChange={handleChange}
                            fullWidth>
                            {
                              RecurringPaymentDuration.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))
                            }
                          </TextField>
                        </Box>
                      }
                      {
                        showBillDetails
                        &&
                          formData.RecurringPaymentDuration === "UNTIL_END_DATE"
                          ?
                          <Box 
                            className="flexFSSBRow"
                            gap="15px">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                slotProps={{ 
                                  textField: { 
                                    size: 'small',
                                    fullWidth: true
                                  } 
                                }}
                                shouldDisableDate={disableWeekends}
                                label="Recurring end date"
                                name="RecurringEndDate"
                                minDate={dayjs()}
                                value={formData.RecurringEndDate}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "RecurringEndDate",
                                      value: e
                                    }
                                  });
                                }}/>
                            </LocalizationProvider>
                            <TextField 
                              size='small'
                              label="Last payment amount(optional)"
                              fullWidth
                              type='number'
                              name="LastPmtAmount"
                              value={formData.LastPmtAmount}
                              onChange={handleChange}
                              />
                          </Box>
                          : 
                            formData.RecurringPaymentDuration === "UNTIL_NUM_OF_PAYMENT"
                            ?
                            <Box 
                              className="flexFSSBRow"
                              gap="15px">
                              <TextField 
                                size='small'
                                label="Number of payments"
                                fullWidth
                                type='number'
                                name="NumberOfPmts"
                                value={formData.NumberOfPmts}
                                onChange={handleChange}
                                />
                              <TextField 
                                size='small'
                                label="Last payment amount(optional)"
                                fullWidth
                                type='number'
                                name="LastPmtAmount"
                                value={formData.LastPmtAmount}
                                onChange={handleChange}
                                />
                            </Box>
                            :
                            <></>
                      }
                      <Box 
                        className="flexFSSBRow"
                        gap="15px">
                        <TextField 
                          size='small'
                          label="Invoice no. (optional)"
                          fullWidth
                          helperText={
                            <Typography
                              variant='caption'
                              color={"grey"}>
                              Add an invoice number to help your vendor track and reconcile payments
                            </Typography>
                          }
                          name="InvoiceNumber"
                          value={formData.InvoiceNumber}
                          onChange={handleChange}
                          />
                        <TextField 
                          size='small'
                          label="Note to self (optional)"
                          fullWidth
                          name="Description"
                          value={formData.Description}
                          onChange={handleChange}
                          />
                      </Box>
                    </Box>
                  }
                  {
                    !showBillDetails
                    &&
                    <Link
                      disabled={!(formData.Amount !== "")}
                      onClick={() => {
                        setShowBillDetails(!showBillDetails);
                        fillDefaultLineItemAmount();
                      }}>
                      Add bill details
                    </Link>
                  }
                  {
                    showBillDetails && formData.PaymentFrequency === 1
                    &&
                    <Box mt="30px">
                      <Typography variant='h6'>Bill detail</Typography>
                      <Box
                        overflow={"auto"}
                        maxHeight={"250px"}> 
                        <Table>
                          <TableHead>
                            <TableCell sx={{ width: "40px" }}></TableCell>
                            <TableCell sx={{ pl: 1 }}>Product</TableCell>
                            <TableCell sx={{ pl: 1 }}>Expense account</TableCell>
                            <TableCell sx={{ pl: 1 }}>Description</TableCell>
                            <TableCell sx={{ pl: 1 }}>Quantity</TableCell>
                            <TableCell sx={{ pl: 1 }}>Amount</TableCell>
                            <TableCell></TableCell>
                          </TableHead>
                          <TableBody>
                            {
                              lineItemsList.map((row, index) => (
                                <TableRow
                                  key={index}>
                                  <TableCell sx={{ p: 1 }}>{index + 1}</TableCell>
                                  <TableCell 
                                    sx={{ p: 1, maxWidth: "20%", width: "20%" }}>
                                    <TextField 
                                      select
                                      fullWidth
                                      size='small'
                                      name='ProductId'
                                      sx={{ width: "100%" }}
                                      value={lineItemsList[index].ProductId}
                                      onChange={(e) => handleLineItemChange(index, e)}
                                      >
                                      {
                                        productsList.map((item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item}>
                                            {item.name || <NA />}
                                          </MenuItem>
                                        ))
                                      }
                                    </TextField>
                                  </TableCell>
                                  <TableCell 
                                    sx={{ p: 1, maxWidth: "20%", width: "20%" }}>
                                    <TextField
                                      select 
                                      fullWidth
                                      size='small'
                                      name='ExpensesAccountId'
                                      sx={{ width: "100%" }}
                                      value={lineItemsList[index].ExpensesAccountId}
                                      onChange={(e) => handleLineItemChange(index, e)}
                                      >
                                      {
                                        expensesAccountsList.map((item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item.id}>
                                            {item.account_name || <NA />}
                                          </MenuItem>
                                        ))
                                      }
                                    </TextField>
                                  </TableCell>
                                  <TableCell 
                                    sx={{ p: 1, maxWidth: "25%", width: "20%" }}>
                                    <TextField 
                                      fullWidth
                                      size='small'
                                      name='Description'
                                      sx={{ width: "100%" }}
                                      value={lineItemsList[index].Description}
                                      onChange={(e) => handleLineItemChange(index, e)}
                                      />
                                  </TableCell>
                                  <TableCell 
                                    sx={{ p: 1, maxWidth: "60px", width: "60px" }}>
                                    <TextField 
                                      fullWidth
                                      size='small'
                                      type='number'
                                      name='Quantity'
                                      value={lineItemsList[index].Quantity}
                                      onChange={(e) => handleLineItemChange(index, e)}
                                      />
                                  </TableCell>
                                  <TableCell sx={{ p: 1 }}>
                                    <AmountTextField  
                                      hideCurrencySelector={true}
                                      currencyName="Currency"
                                      currency={formData.Currency}
                                      onCurrencyChange={handleChange}
                                      size='small'
                                      type='number'
                                      name='Amount'
                                      value={lineItemsList[index].Amount}
                                      onChange={(e) => handleLineItemChange(index, e)}
                                      error={lineItemsError}
                                      />
                                  </TableCell>
                                  <TableCell sx={{ p: 1 }}>
                                    {
                                      lineItemsList.length > 1
                                      &&
                                      <IconButton
                                        onClick={() => removeLineItem(index)}>
                                        <DeleteOutlineIcon sx={{ color: "grey" }}/>
                                      </IconButton>
                                    }
                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </Box>
                      <Link 
                        sx={{ marginTop: "10px" }}
                        onClick={addLineItem}>
                        Add another detail
                      </Link>
                    </Box>
                  }
                </Box>
                <Box
                  margin={"40px 0px 0px 0px"}>
                  {
                    showBillDetails && formData.PaymentFrequency === 1
                    &&
                    <Box 
                      className="flexFEFEColumn">
                      <Typography>
                        Total
                      </Typography>
                      <Typography 
                        variant='h6'
                        fontWeight={600}>    
                        {handleShowAmount(formData.Currency, 
                          handleCalculateAmount(formData.Currency, formData.Amount || 0))}
                      </Typography>
                    </Box>
                  }
                  {console.log(formData.Amount)}
                  {
                    mode === 1
                    ?
                    <Box  
                      className="flexCenterFERow" 
                      sx={{ gap: "20px", marginTop: "20px" }}>
                      {
                        formData.PaymentFrequency === 1
                        &&
                        <LoadingButton  
                          variant='text'
                          fullWidth
                          loading={loading}
                          onClick={handleSubmit}
                          disabled={!(handleCalculateAmount(formData.Currency, formData.Amount || 0) !== 0 && formData.Vendor !== null) || loadingPay}>
                          Save and close
                        </LoadingButton>
                      }
                      <LoadingButton 
                        variant='contained'
                        fullWidth
                        loading={loadingPay}
                        onClick={handlePay}
                        disabled={!(handleCalculateAmount(formData.Currency, formData.Amount || 0) !== 0 && formData.Vendor !== null) || loading}>
                        Pay
                      </LoadingButton>
                    </Box>
                    :
                      mode === 2
                      ?
                      <Box  
                        className="flexCenterFERow" 
                        sx={{ gap: "20px", marginTop: "20px" }}>
                        <LoadingButton 
                          variant='contained'
                          fullWidth
                          loading={loading}
                          onClick={handleSubmit}
                          disabled={!(handleCalculateAmount(formData.Currency, formData.Amount || 0) !== 0)}>
                          Save
                        </LoadingButton>
                      </Box>
                      :
                      <></>
                  }
                </Box> 
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateBillModal;