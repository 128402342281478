import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../../components/topBar/TopBar';
import TabsPanel from '../../../components/tabs/TabsPanel';
import VendorsPage from './vendorsPage/VendorsPage';
import PaymentMethodsPage from './paymentMethodsPage/PaymentMethodsPage';
import BuyerWalletsPage from './buyerWalletsPage/BuyerWalletsPage';
import CardOnFilePage from './cardOnFilePage/CardOnFilePage';
import ApprovalsPage from './approvalsPage/ApprovalsPage';

const PayIndex = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(localStorage.getItem("payTabSET"));

  useEffect(() => {
    if("child" in params){
      switch (params.child) {
        case "vendors": 
          localStorage.setItem("payTabSET", "1"); 
          setCurrentTab("1"); 
          break;
        case "payment-methods": 
          localStorage.setItem("payTabSET", "2"); 
          setCurrentTab("2"); 
          break;
        case "buyer-wallets": 
          localStorage.setItem("payTabSET", "3"); 
          setCurrentTab("3"); 
          break;
        case "card-on-file": 
          localStorage.setItem("payTabSET", "4"); 
          setCurrentTab("4"); 
          break;
        case "approvals": 
          localStorage.setItem("payTabSET", "5"); 
          setCurrentTab("5"); 
          break;

        default: {
          localStorage.setItem("payTabSET", "1");
          setCurrentTab("1");
          navigate("/settings/pay");
        }
      }
      navigate("/settings/pay")
    }else{
      if(!localStorage.getItem("payTabSET")){
        localStorage.setItem("payTabSET", "1");
        setCurrentTab("1");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TopBar
        title="Settings - Pay"
        />
      
      <Box className="flexCenterSBRow" mb={"10px"}>
        <TabsPanel
          value={currentTab+""}
          handleChange={(e, value) => {
            localStorage.setItem("payTabSET", value);
            setCurrentTab(value);
          }}
          links={[
            { value: "1", label: "Vendors", path: "/vendors" },
            { value: "2", label: "Payment methods", path: "/payment-methods" },
            // { value: "3", label: "Buyer Wallets", path: "/buyer-wallets" },
            // { value: "4", label: "Card on File", path: "/card-on-file" },
            { value: "5", label: "Approvals", path: "/approvals" }
          ]}
          />
      </Box>
      {
        currentTab === "1"
        ?
          <VendorsPage />
          :
            currentTab === "2"
            ?
              <PaymentMethodsPage />
              :
                currentTab === "3"
                ?
                  <ApprovalsPage />
                  :
                    currentTab === "4"
                    ?
                      <ApprovalsPage />
                      :
                        currentTab === "5"
                        ?
                          <ApprovalsPage />
                          :
                          <></>
      }
    </Box>
  )
}

export default PayIndex;