import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { 
  Box,
} from '@mui/material';

import TopBar from '../../components/topBar/TopBar';
import TabsPanel from '../../components/tabs/TabsPanel';
import BudgetPage from './budgetPage/BudgetPage';
import WalletActivityPage from './walletActivityPage/WalletActivityPage';
import ApprovalsPage from './approvalsPage/ApprovalsPage';
import ExpensesPage from './expensesPage/ExpensesPage';
import ReimbursementsPage from './reimbursementsPage/ReimbursementsPage';

const ExpenseIndex = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(localStorage.getItem("expenseTab"));

  useEffect(() => {
    if("child" in params){
      switch (params.child) {
        case "budget": 
          localStorage.setItem("expenseTab", "1"); 
          setCurrentTab("1"); 
          break;
        case "wallet-activity": 
          localStorage.setItem("expenseTab", "2"); 
          setCurrentTab("2"); 
          break;
        case "expenses": 
          localStorage.setItem("expenseTab", "3"); 
          setCurrentTab("3"); 
          break;
        case "approvals": 
          localStorage.setItem("expenseTab", "4");  
          setCurrentTab("4"); 
          break;
        case "reimbursements":  
          localStorage.setItem("expenseTab", "5"); 
          setCurrentTab("5"); 
          break;

        default: {
          localStorage.setItem("expenseTab", "1"); 
          setCurrentTab("1");
          navigate("/expense");
        }
      }
      navigate("/expense")
    }else{
      if(!localStorage.getItem("expenseTab")){
        localStorage.setItem("expenseTab", "1");
        setCurrentTab("1");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TopBar
        title="Expense Management"
        />
      
      <Box className="flexCenterSBRow" mb={"10px"}>
        <TabsPanel
          value={currentTab+""}
          handleChange={(e, value) => {
            localStorage.setItem("expenseTab", value);
            setCurrentTab(value);
          }}
          links={[
            { value: "1", label: "Expenses", path: "/expenses" },
            { value: "2", label: "Approvals", path: "/approvals" },
            { value: "3", label: "Reimbursements", path: "/reimbursements" }
          ]}
          />
      </Box>
      {
        currentTab === "1"
        ?
          <ExpensesPage />
          :
            currentTab === "2"
            ?
              <ApprovalsPage />
              :
                currentTab === "3"
                ?
                  <ReimbursementsPage />
                  :
                  <></>
      }
    </Box>
  )
}

export default ExpenseIndex;