import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Link,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../components/hooks/useTablePaginationInit';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import SearchTextField from '../../../components/textFields/SearchTextField';
import useCurrencyService from '../../../components/hooks/useCurrencyService';
import useCompanyRole from '../../../components/hooks/useCompanyRole';
import TableLoading from '../../../components/loaders/TableLoading';
import NATable from '../../../components/utils/NATable';

import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

import data from "./data.json";

import { 
  GetReimbursements 
} from '../../../redux/actions/expense/reimbursementsActions';

import ExpensesModal from '../expensesPage/ExpensesModal';

import { 
  OPEN_IMAGE_VIEWER, 
  SET_IMAGES_URL 
} from '../../../redux/actionTypes';

import global from "../../../global.scss";
import "./styles.scss";
import { PayReimbursements } from '../../../redux/actions/expense/reimbursementsActions';

const ReimbursementsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.expense);
  const companyId = useSelectedCompany();
  const companyRole = useCompanyRole();
  const [loading, setLoading] = useState(true);
  const { handleShowAmount } = useCurrencyService();
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [expensesModalOpen, setExpensesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);
  
  const [reimbursementsList, setReimbursementsList] = useState([]);
  const [reimbursementsTotal, setReimbursementsTotal] = useState(0);
  
  const [selectedExpensesList, setSelectedExpensesList] = useState([]);
  
  const [selected, setSelected] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedTotal, setSelectedTotal] = useState(0);

  const [payId, setPayId] = useState(null);
  const [loadingPay, setLoadingPay] = useState(false);
    
  const [
    page, 
    rowsPerPage, 
    handleChangePage,
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

  const tableHeadingsList = [
    {
      label: "Date",
      sort: "created_at",
    },
    {
      label: "Transaction",
      sort: "name",
    },
    {
      label: "Amount",
      sort: "amount",
    },
    {
      label: "Employee",
      sort: "ref_employee_id",
    },
    {
      label: "Status",
      sort: "status",
    },
    {
      label: "",
      sort: null,
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetReimbursements();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    let total = 0;
    selected.forEach(i => total += data[i].Amount);
    setSelectedTotal(total);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCount]);

  useEffect(() => {
    try {
      formatReimbursementsList(state.reimbursementsList.records || []);
      setReimbursementsTotal(state.reimbursementsList.total || 0)
    } catch (err) {}
  }, [state]);

  const formatReimbursementsList = (list) => {
    setReimbursementsList(list);
  };

  async function handleGetReimbursements(customLoading){
    let query = "";
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetReimbursements(companyId, page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  const handleClearSelectedExpensesList = () => {
    setSelectedExpensesList([]);
  };

  const handlePay = (id) => {
    setPayId(id);
    
    let obj = {
      "expense_id": id
    };

    dispatch(PayReimbursements(companyId, obj, setLoadingPay)).then(() => {
      handleGetReimbursements(setLoadingPay).then(() => {
        setPayId(null);
      })
    })
  };


  const getExpenseChipColor = (status) => {
    switch(status){
      case "Approved": return "success";
      case "Scheduled": return "info";
      case "Paid": return "success";
      case "Failed": return "error";

      default: return "default";
    }
  };


  return (
    <Box>
       
      <ExpensesModal 
        open={expensesModalOpen}
        setOpen={setExpensesModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetReimbursements}
        />
      
      <Box 
        className="flexCenterSBRow" 
        gap="15px"
        mb={"10px"}>
        <Box></Box>

        <Box
          className="flexCenter_Row" 
          gap={"15px"}>
          <SearchTextField 
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            />
        </Box>
      </Box>

      {
        selectedCount > 0
        &&
        <Box
          className="flexCenterSBRow" 
          gap={"20px"}
          sx={{ padding: "10px 20px"}}>
          <Box
            className="flexCenterSBRow" 
            gap={"20px"}>
            <Typography>
              {selectedCount} Selected
            </Typography>
            <Typography>
              Total Reimbursable: <b>{handleShowAmount("USD", selectedTotal)}</b>
            </Typography>
          </Box>

          <Button
            variant='contained'>
            Reimburse
          </Button>
        </Box>
      }

      {
        loading
        ?
        <TableLoading />
        :
          <>
          {
            selectedExpensesList.length > 0
            &&
            <Box
              className="reimburse__multiselect flexCenterSBRow">

              <Box
                className="flexCenterSBRow" 
                gap={"20px"}>
                <Typography
                  variant='body2'
                  fontWeight={600}>
                  {selectedExpensesList.length} Expense selected
                </Typography>
              </Box>

              <Box
                className="flexCenter_Row"
                gap={"10px"}>
                <Button
                  variant='contained'>
                  Reimburse
                </Button>
              </Box>
            </Box>
          }
       
          <Table className='table'>
            <TableHead>
              <TableRow>
                {
                  companyRole === "Admin"
                  &&
                    reimbursementsList.length > 0
                    &&
                    <TableCell width={"60px"}>
                      <Checkbox 
                        checked={selectedExpensesList.length === reimbursementsList.length}
                        onChange={(e) => {
                          if(e.target.checked === true){
                            let temp = reimbursementsList.map(i => {
                              return i.expense_id
                            });
                            setSelectedExpensesList([ ...temp ]);
                          } else {
                            setSelectedExpensesList([]);
                          }
                        }}
                        />
                    </TableCell>
                }
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex} 
                      align={heading.align || "left"}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}
                      align={heading.align || "left"}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                reimbursementsList.length === 0
                ?
                <NATable 
                  label={"No reimbursement found"}
                  />
                :
                reimbursementsList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    {
                      companyRole === "Admin"
                      &&
                      <TableCell width={"60px"}>
                        <Checkbox 
                          checked={selectedExpensesList.includes(row.expense_id)}
                          onChange={(e) => {
                            if(e.target.checked === true){
                              let temp = selectedExpensesList;
                              temp.push(row.expense_id);
                              setSelectedExpensesList([ ...temp ]);
                            } else {
                              let temp = selectedExpensesList;
                              temp = temp.filter(i => i !== row.expense_id);
                              setSelectedExpensesList([ ...temp ]);
                            }
                          }}
                          />
                      </TableCell>
                    }
                    <TableCell>{row.created_at && new Date(row.created_at).toDateString().slice(4)}</TableCell>
                    <TableCell>
                      <Box>
                        <Typography>{row.name}</Typography>
                      </Box>
                      <Typography
                        color={"grey"} 
                        className='longText'
                        sx={{ width: "250px", fontSize: 12 }}
                        >
                        {row.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography fontWeight={600}>
                          {row.amount && handleShowAmount("USD", row.amount)}
                        </Typography>
                        <Typography  variant='caption' color={"grey"}>{row?.ref_payment_method_id?.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Chip 
                        size='small'
                        color='info'
                        label={row.ref_employee_id?.employee_name}
                        />
                    </TableCell>
                    <TableCell>
                      <Chip 
                        color={getExpenseChipColor(row.status)}
                        size='small'
                        label={row.status}
                        />
                    </TableCell>
                    <TableCell>
                      <Box className="flexCenter_Row" gap={"10px"}>
                        <IconButton
                          disabled={row?.expense_attachments.length === 0}
                          onClick={() => {
                            setUpdate(row?.expense_attachments.map(i => { return i.attachment_path }))
                            dispatch({ type: OPEN_IMAGE_VIEWER });
                            dispatch({ type: SET_IMAGES_URL, payload: row?.expense_attachments.map(i => { return i.attachment_path }) });
                          }}>
                          <ReceiptLongOutlinedIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setModalTitle(
                              <>
                                {"Activity messages for"}&nbsp;
                                <span
                                  style={{ color: global["secondary"] }}>
                                  {row?.name}
                                </span>
                              </>);
                            setUpdate({
                              ...row,
                              defaultMode: 4
                            });
                            setExpensesModalOpen(!expensesModalOpen);
                          }}>
                          <MessageOutlinedIcon />
                        </IconButton>                        
                        {
                          (companyRole === "Admin" && row.status === "Approved" && selectedCount <= 0)
                          &&
                          ( 
                            (payId === row.expense_id && loadingPay)
                            ?
                            <CircularProgress 
                              size={26} 
                              sx={{ margin: "7px" }}/>
                            :
                            <Link
                              sx={{ marginLeft: "10px" }}
                              onClick={() => handlePay(row.expense_id)}>
                              Pay
                            </Link>
                          )
                        }
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25]} 
                  colSpan={10}
                  count={reimbursementsTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
          </>
      }
    </Box>
  )
}

export default ReimbursementsPage;