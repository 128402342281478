import * as actionTypes from "../actionTypes.js";

const initialState = {
  walletBalance: null,
  activitiesList: null,
};

const internationalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WALLET_BALANCE_INTERNATIONAL:
      return { ...state, walletBalance: action.payload };
    case actionTypes.GET_WALLET_ACTIVITIES_INTERNATIONAL:
      return { ...state, activitiesList: action.payload };


    case actionTypes.LOG_OUT:
      return initialState;
    
    default:
      return state;
  }
};

export default internationalReducer;