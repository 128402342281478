import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Modal, 
  Step, 
  StepLabel, 
  Stepper, 
  TextField, 
  Tooltip, 
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import useCurrencyService from '../../../components/hooks/useCurrencyService';
import AmountTextField from '../../../components/textFields/AmountTextField';
import useStepperInit from '../../../components/hooks/useStepperInit';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../components/buttons/Button';
import NA from '../../../components/utils/NA';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  CreateExpense, 
  GetExpenseMessages, 
  UpdateExpense
} from '../../../redux/actions/expense/expensesActions';
import { 
  GetEmployeesSearch, 
  UploadFiles, 
} from '../../../redux/actions/miscellaneousActions';

import global from "../../../global.scss";
import "./styles.scss";

const Data = {
  Currency: "USD",
  Name: "",
  Description: "",
  Amount: "",
  CategoryId: "",
  PaymentMethodId: "",
  CostAccountId: "",
  EmployeeId: ""
};

const ExpensesModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.expense);
  const other = useSelector(state => state.other);
  const companyId = useSelectedCompany();
  const { handleEditAmount, handleCalculateAmount } = useCurrencyService();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const [categoriesList, setCategoriesList] = useState([]);
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const [costAccountsList, setCostAccountsList] = useState([]);

  const [employeesList, setEmployeesList] = useState([]);
  const [selectedEmployeesList, setSelectedEmployeesList] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const [expenseMessages, setExpenseMessages] = useState([]);

  const [
    steps,
    activeStep, 
    handleReset, 
    handleNext,
    handleBack
  ] = useStepperInit(['Details', 'Receipt', 'Additional employees']);

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
    handleReset();
    setEmployeesList([]);
    setSelectedEmployeesList([]);
    setExpenseMessages([]);
    setPaymentMethodsList([]);
    setSelectedFiles([]);
    setPreviews([]);
  };

  useEffect(() => {
    if(open) {
      setSelectedFiles([]);
      setPreviews([]);
      dispatch(GetEmployeesSearch(companyId, 1, 1000));
      if(update?.defaultMode === 1){
        // mode = 1 - create expense
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - edit expense
        console.log(update)
        setMode(2);
        setFormData({
          Name: update.name || "",
          Description: update.description || "",
          Currency: update.currency || "USD",
          Amount: handleEditAmount(update.currency || "USD", update.amount || 0),
          CategoryId: update?.ref_category_id?.category_id || "",
          PaymentMethodId: update?.ref_payment_method_id?.id || "", 
          CostAccountId: update?.ref_cost_account_id?.id || "",
        });
        // edit expense
        let tempCategory = categoriesList.find(i => i.category_id === update?.ref_category_id?.category_id);
        if (tempCategory){
          setPaymentMethodsList(tempCategory.payment_methods.filter(i => i.is_active === true))
        }else{
          setPaymentMethodsList([]);
        }
      }else if(update?.defaultMode === 3){
        // mode = 3 - view employees
        setMode(3);
        setSelectedEmployeesList(update?.expense_employees || []);
      }else if(update?.defaultMode === 4){
        // mode = 4 - view messages
        setMode(4);
        dispatch(GetExpenseMessages(companyId, update?.expense_id, setLoading));
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatCategoriesList(state.categoriesList.records || []);
      formatCostAccountsList(state.costAccountsList.records || []);
      if(mode === 4) formatExpenseMessages(state.expenseMessages || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    try {
      formatEmployeesList(other.employeesList || []);
    } catch (err) {}
  }, [other]);

  const formatCategoriesList = (list) => {
    setCategoriesList(list.filter(i => i.is_active === true));
  };

  const formatCostAccountsList = (list) => {
    setCostAccountsList(list);
  };

  const formatEmployeesList = (list) => {
    setEmployeesList(list);
  };

  const formatExpenseMessages = (list) => {
    setExpenseMessages(list);
  };

  const handleChange = (e) => {
    if(e.target.name === "CategoryId"){
      let tempCategory = categoriesList.find(i => i.category_id === e.target.value);
      if (tempCategory){
        setPaymentMethodsList(tempCategory.payment_methods.filter(i => i.is_active === true))
      }else{
        setPaymentMethodsList([]);
      }
    }

    if(e.target.name === "EmployeeId"){
      setSelectedEmployeesList([ ...selectedEmployeesList, e.target.value ]);
      setEmployeesList(employeesList.filter(i => i.employee_id !== e.target.value.employee_id ));
      setFormData({ ...formData, [e.target.name]: "" });
    }else{
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };  

  // --------------- handle create ---------------
  const handleSubmit = async () => {
    try {
      if(mode === 1){
        // mode = 1 -> create expense
        let obj = {
          "name": formData.Name,
          "description": formData.Description || "",
          "amount": handleCalculateAmount(formData.Currency, formData.Amount),
          "currency": formData.Currency,
          "ref_category_id": formData.CategoryId,
          "ref_payment_method_id": formData.PaymentMethodId,
          "ref_cost_account_id": formData.CostAccountId,
          "employees": selectedEmployeesList.map(i => {
            return i.employee_id;
          }),
          "attachment_id_list": selectedFiles.length > 0 ? await handleUploadImages() : []
        };

        dispatch(CreateExpense(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2){
        // mode = 2 -> edit expense
        let obj = {
          "name": formData.Name,
          "description": formData.Description || "",
          "amount": handleCalculateAmount(formData.Currency, formData.Amount),
          "currency": formData.Currency,
          "ref_category_id": formData.CategoryId,
          "ref_payment_method_id": formData.PaymentMethodId,
          "ref_cost_account_id": formData.CostAccountId,
        };
        
        dispatch(UpdateExpense(companyId, update?.expense_id, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  const handleRemoveSelectedEmployee = (id) => {
    let selectedTemp = selectedEmployeesList;
    setSelectedEmployeesList(selectedTemp.filter(i => i.employee_id !== id));
    formatEmployeesList(other.employeesList.records || []);
  };


  const handleUploadImages = async () => {
    let path = [];

    for(let i = 0 ; i < selectedFiles.length ; i++){
      const image = new FormData();
      image.append("new_file", selectedFiles[i]);
      await dispatch(UploadFiles(image, setLoading)).then(({res, statusCode}) => {
        if(statusCode === 201) path.push(res.data.file_path)
      });
    };

    return path;
  };

  useEffect(() => {
    let previewTemp = [];

    for(let i = 0 ; i < selectedFiles.length ; i++){
      const objectUrl = URL.createObjectURL(selectedFiles[i])
      previewTemp.push(objectUrl);
    };
    setPreviews(previewTemp);
  }, [selectedFiles]);

  const onSelectFile = e => {
    console.log(e.target.files)

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFiles([]);
      return;
    }  

    let temp = [];

    let index = 0;
    for(let i = 0 ; i < e.target.files.length ; i++){
      if(index > 3) break;
      index++;
      temp.push(e.target.files[i]);
    };

    temp = [ ...selectedFiles, ...temp ];

    setSelectedFiles(temp.slice(0, 3));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className={`fullpage__modal animate__animated animate__fadeIn`}>
          
        <ModalTopBar
          onBack={handleClose}
          title={title} 
          />

        <Container 
          maxWidth="sm">
          {
            mode === 1
            ?
            <Box 
              className="flex__Column"
              gap={"40px"}
              marginTop={"80px"}
              pt="20px">
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              
              {
                activeStep === 0
                ?
                <Box
                  className="flex__Column"
                  gap={"20px"}>
                  <Box
                    className="flexCenter_Row"
                    gap="20px">
                    <AmountTextField 
                      currencyName="Currency"
                      currency={formData.Currency}
                      onCurrencyChange={handleChange}
                      label="Amount"
                      fullWidth
                      name="Amount"
                      value={formData.Amount || ""}
                      onChange={handleChange}
                      />
                    <TextField 
                      label="Merchant"
                      fullWidth
                      name="Name"
                      value={formData.Name || ""}
                      onChange={handleChange}
                      />
                  </Box>
                  <TextField 
                    label="Description"
                    fullWidth
                    name="Description"
                    value={formData.Description || ""}
                    onChange={handleChange}
                    />
                  <TextField 
                      select
                      label="Category"
                      fullWidth
                      name="CategoryId"
                      value={formData.CategoryId}
                      onChange={handleChange}
                      >
                      {
                        categoriesList.length === 0
                        ?
                          <MenuItem disabled>
                            No category found
                          </MenuItem>
                        :
                        categoriesList.map((item, index) => (
                          <MenuItem key={index} value={item.category_id}>
                            <Box className="flexCenter_Row" gap={"10px"}>
                              <Typography>
                                {item.category}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))
                      }
                    </TextField>
                    {
                      formData.CategoryId
                      &&
                      <TextField 
                        select
                        label="Payment method"
                        fullWidth
                        name="PaymentMethodId"
                        value={formData.PaymentMethodId}
                        onChange={handleChange}
                        >
                        {
                          paymentMethodsList.length === 0
                          ?
                            <MenuItem disabled>
                              No payment method found
                            </MenuItem>
                          :
                          paymentMethodsList.map((item, index) => (
                            <MenuItem key={index} value={item?.payment_method?.id}>
                              <Box className="flexCenter_Row" gap={"10px"}>
                                <Typography>
                                  {item?.payment_method?.name}
                                </Typography>
                                {
                                  item?.payment_method?.is_reimbursable === true
                                  &&
                                  <Chip
                                    size='small'
                                    label={"Reimbursable"} 
                                    />
                                }
                              </Box>
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    }
                    <TextField 
                      select
                      label="Cost account"
                      fullWidth
                      name="CostAccountId"
                      value={formData.CostAccountId}
                      onChange={handleChange}
                      >
                      {
                        costAccountsList.length === 0
                        ?
                          <MenuItem disabled>
                            No cost account found
                          </MenuItem>
                        :
                        costAccountsList.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            <Box className="flexCenter_Row" gap={"10px"}>
                              <Typography>
                                {item?.ref_cost_object_id?.name || "NA"}&nbsp;{">"}&nbsp;
                                {item?.name || "NA"}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))
                      }
                    </TextField>
                </Box>
                :
                  activeStep === 1
                  ?
                  <Box
                    className="flexCenterCenterColumn"
                    gap={"20px"}
                    >
                    {selectedFiles.length < 4 &&  
                      <Box
                        className="flexCenter_Row"
                        gap={"20px"}>
                        {
                          previews.map((image, imageIndex) => (
                            <Box
                              key={imageIndex}
                              sx={{ position: "relative" }}>
                              <Box
                                color='white'
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "10px",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  top: "-15px",
                                  right: "-15px",
                                  backgroundColor: "lightgrey",
                                  cursor: "pointer",
                                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                }}
                                onClick={() => {
                                  let temp = selectedFiles.filter((item, index) => index !== imageIndex);
                                  setSelectedFiles(temp);
                                }}>
                                <CloseRoundedIcon 
                                  fontSize='small'
                                  sx={{ color: "black" }}/>
                              </Box>
                              {
                                selectedFiles[imageIndex]?.type === "application/pdf"
                                ?
                                <iframe
                                  src={image}
                                  title="PDF Viewer"
                                  width="100%"
                                  height="300px"
                                ></iframe>
                                :
                                <img 
                                  key={imageIndex}
                                  src={image}
                                  alt='expense-receipt'
                                  className='expense__receipt'
                                  style={{ height: "300px" }}
                                  /> 
                              }
                            </Box>
                          ))
                        }
                      </Box>
                    }
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      disabled={selectedFiles.length > 2}
                      tabIndex={-1}>
                      {
                        selectedFiles.length === 0
                        ?
                          "Upload attachment"
                        :
                          "Add more attachment"
                      }
                      <VisuallyHiddenInput 
                        type="file" 
                        name="myImage"
                        multiple
                        accept=".png, .jpeg, .jpg, .pdf" 
                        onChange={onSelectFile} />
                    </Button>
                  </Box>
                  :
                    activeStep === 2
                    ?
                      <Box
                        className="flex__Column"
                        gap={"20px"}>
                        <TextField 
                          select
                          label="Select employees"
                          fullWidth
                          name="EmployeeId"
                          value={formData.EmployeeId}
                          onChange={handleChange}
                          >
                          {
                            employeesList.length === 0
                            ?
                              <MenuItem disabled>
                                No employee found
                              </MenuItem>
                            :
                            employeesList.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                <Box className="flexCenter_Row" gap={"10px"}>
                                  <Typography>
                                    {item.employee_name}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            ))
                          }
                        </TextField>
                        {
                          selectedEmployeesList.length > 0
                          &&
                          <Typography
                            variant='subtitle1'>
                            Selected employees
                          </Typography>
                        }
                        <Box
                          className="flex__Column"
                          gap={"20px"}>
                          {
                            selectedEmployeesList.map((item, index) => (
                            <Box
                              key={index}
                              className="flexCenterSBRow spendwallet__emp"
                              gap={"10px"}>
                              <Box
                                className="flexCenter_Row"
                                gap={"10px"}>
                                <Typography>
                                  {item.employee_name || "NA"}
                                </Typography>
                              </Box>
                              <IconButton
                                onClick={() => {
                                  handleRemoveSelectedEmployee(item.employee_id);
                                }}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    :
                    <></>
              }
              <Box
                className="flexCenterFERow"
                gap={"20px"}
                marginTop={"20px"}>
                {
                  (activeStep === 1 || activeStep === 2)
                  &&
                  <Button
                    variant='text'
                    onClick={handleBack}
                    disabled={loading}>
                    Back
                  </Button>
                }
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  disabled={activeStep === 0
                    ? !(formData.Amount !== "" && formData.Name !== "" &&
                      formData.CategoryId !== "" && formData.PaymentMethodId !== "" && formData.CostAccountId !== "")
                    : activeStep === 1
                      ? !(true)
                      : activeStep === 2 
                        ? false
                        : true}
                  onClick={activeStep === 2
                      ? handleSubmit
                      : handleNext }>
                  { activeStep === 2 ? "Continue" : "Next" }
                </LoadingButton>
              </Box>
            </Box>
            :
              mode === 2
              ?
              <Box
                className="flex__Column"
                gap={"20px"}
                pt="20px">
                <Box
                  className="flexCenter_Row"
                  gap="20px">
                  <AmountTextField 
                    currencyName="Currency"
                    currency={formData.Currency}
                    onCurrencyChange={handleChange}
                    label="Amount"
                    fullWidth
                    name="Amount"
                    value={formData.Amount || ""}
                    onChange={handleChange}
                    />
                  <TextField 
                    label="Merchant"
                    fullWidth
                    name="Name"
                    value={formData.Name || ""}
                    onChange={handleChange}
                    />
                </Box>
                <TextField 
                  label="Description"
                  fullWidth
                  name="Description"
                  value={formData.Description || ""}
                  onChange={handleChange}
                  />
                <TextField 
                  select
                  label="Category"
                  fullWidth
                  name="CategoryId"
                  value={formData.CategoryId}
                  onChange={handleChange}
                  >
                  {
                    categoriesList.length === 0
                    ?
                      <MenuItem disabled>
                        No category found
                      </MenuItem>
                    :
                    categoriesList.map((item, index) => (
                      <MenuItem key={index} value={item.category_id}>
                        <Box className="flexCenter_Row" gap={"10px"}>
                          <Typography>
                            {item.category}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))
                  }
                </TextField>
                {
                  formData.CategoryId
                  &&
                  <TextField 
                    select
                    label="Payment method"
                    fullWidth
                    name="PaymentMethodId"
                    value={formData.PaymentMethodId}
                    onChange={handleChange}
                    >
                    {
                      paymentMethodsList.length === 0
                      ?
                        <MenuItem disabled>
                          No payment method found
                        </MenuItem>
                      :
                      paymentMethodsList.map((item, index) => (
                        <MenuItem key={index} value={item?.payment_method?.id}>
                          <Box className="flexCenter_Row" gap={"10px"}>
                            <Typography>
                              {item?.payment_method?.name}
                            </Typography>
                            {
                              item?.payment_method?.is_reimbursable === true
                              &&
                              <Chip
                                size='small'
                                label={"Reimbursable"} 
                                />
                            }
                          </Box>
                        </MenuItem>
                      ))
                    }
                  </TextField>
                }
                <TextField 
                  select
                  label="Cost account"
                  fullWidth
                  name="CostAccountId"
                  value={formData.CostAccountId}
                  onChange={handleChange}
                  >
                  {
                    costAccountsList.length === 0
                    ?
                      <MenuItem disabled>
                        No cost account found
                      </MenuItem>
                    :
                    costAccountsList.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        <Box className="flexCenter_Row" gap={"10px"}>
                          <Typography>
                            {item?.ref_cost_object_id?.name || "NA"}&nbsp;{">"}&nbsp;
                            {item?.name || "NA"}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))
                  }
                  </TextField>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  disabled={!(formData.Amount !== "" && 
                    formData.Name !== "" && formData.CategoryId !== "" &&
                    formData.PaymentMethodId !== "" && formData.CategoryId !== ""
                    )}
                  onClick={handleSubmit}>
                  {"Update"}
                </LoadingButton>
{/* 
                <Typography
                  mt={"40px"}
                  variant='h6'>
                  Receipts
                </Typography> */}

                {/* <Grid
                  container
                  spacing={2}
                  >
                  {
                    update?.expense_attachments.map((receipt, receiptIndex) => (
                      <Grid
                        item
                        lg={6} md={6} sm={6}>
                        {
                          receipt?.attachment_path?.split(".pdf").length === 2 && 
                          receipt?.attachment_path?.split(".pdf")[1] === ""
                          ?
                          <Box
                            className='expense__receipts'
                            sx={{ position: "relative" }}>
                            <Box
                              color='white'
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "10px",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                backgroundColor: "lightgrey",
                                cursor: "pointer",
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                              }}
                              onClick={() => {

                              }}>
                              <CloseRoundedIcon 
                                fontSize='small'
                                sx={{ color: "black" }}/>
                            </Box>
                            <iframe
                              src={receipt?.attachment_path}
                              title="PDF Viewer"
                              className='expense__receipts'
                              style={{
                                width: "100%",
                                height: "350px"
                              }}
                            ></iframe>
                          </Box>
                          :
                          <Box
                            className='expense__receipts'
                            sx={{ position: "relative" }}>
                            <Box
                              color='white'
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "10px",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                backgroundColor: "lightgrey",
                                cursor: "pointer",
                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                              }}
                              onClick={() => {
                              }}>
                              <CloseRoundedIcon 
                                fontSize='small'
                                sx={{ color: "black" }}/>
                            </Box>
                            <img
                              src={receipt?.attachment_path}
                              alt='image-viewer'
                              style={{
                                width: "100%",
                                height: "350px"
                              }}
                              />
                          </Box>
                        }
                      </Grid> 
                    ))
                  }
                </Grid> */}

              </Box>
              : 
                mode === 3
                ?
                <Box
                  className="flex__Column"
                  gap={"20px"}
                  pt="20px">
                  {
                    selectedEmployeesList.map((item, index) => (
                    <Box
                      key={index}
                      className="flexCenterSBRow spendwallet__emp"
                      gap={"10px"}>
                      <Box
                        gap={"10px"}>
                        <Box
                          className="flexCenter_Row"
                           gap={"10px"}>
                          <Typography>
                            {item?.ref_employee_id?.employee_name || <NA />}
                          </Typography>
                          <Chip
                            size='small'
                            label={item?.ref_employee_id?.employee_role || <NA />}
                            sx={{ 
                              color: item?.ref_employee_id?.employee_role === "Admin" 
                                ? global["purple"] 
                                : global["secondary"],
                              backgroundColor: item?.ref_employee_id?.employee_role === "Admin" 
                                ? global["purple_light"] 
                                : global["primary_light"]
                            }}
                            />
                        </Box>
                        <Typography
                          variant='caption'>
                          {item?.ref_employee_id?.employee_email || <NA />}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                  <Button
                    onClick={handleClose}>
                    Close
                  </Button>
                </Box>
                :    
                  mode === 4
                  ?
                  <Box
                    className="flex__Column"
                    gap={"10px"}
                    pt="20px"
                    pb="20px">
                    {
                      loading
                      ?
                      <Box
                        className="flexCenterCenterRow"
                        height={"200px"}>
                        <CircularProgress />
                      </Box>
                      :
                      expenseMessages.length === 0
                      ?
                      <Box
                        className="flexCenterCenterRow"
                        height={"200px"}>
                        <NA  
                          label={'No message found'}/>
                      </Box>
                      :
                      <>
                      <Typography 
                        variant='caption'
                        color={"grey"}>
                        Latest activity first
                      </Typography>
                      {
                        expenseMessages.map((item, index) => (
                        <Box
                          key={index}
                          className="expense__act"
                          gap={"10px"}>
                          <Box
                            className="flex__Column"
                            gap={"10px"}>
                            <Box
                              className="flexCenter_Row"
                              gap={"10px"}>
                              <Typography
                                variant='body2'>
                                <Typography 
                                  variant='caption'
                                  sx={{ color: "grey" }}>
                                  {item.created_at && dayjs(item.created_at).format('MMM DD YYYY, hh:mm A')}&nbsp;-&nbsp;
                                </Typography>
                                <b>{item?.activity || "NA"}</b> :: {item?.comment.split("&&")[0]}
                              </Typography>
                            </Box>
                            {
                              item?.ref_approver_id && item?.ref_approver_id !== null
                              &&
                              <Box
                                className="flexCenter_Row"
                                gap={"10px"}>
                                <Typography
                                  variant='caption'
                                  color={"grey"}
                                  textAlign={"right"}
                                  width={"130px"}>
                                  Action taken by
                                </Typography>
                                <Tooltip
                                  title={item?.ref_approver_id?.employee_email || "NA"}>
                                  <Chip
                                    size='small'
                                    label={item?.ref_approver_id?.employee_name || "NA"}
                                    /> 
                                </Tooltip>
                              </Box>
                            }
                            {
                              item?.comment.split("&&").length > 1
                              &&
                              <Box
                                className="flexFS_Row"
                                gap={"10px"}>
                                <Typography
                                  variant='caption'
                                  color={"grey"}
                                  textAlign={"right"}
                                  minWidth={"130px"}>
                                  Approver Comment
                                </Typography>
                                <Typography
                                  variant='body2'>
                                  {item?.comment.split("&&").slice(1).join("")}
                                </Typography>
                              </Box>
                            }
                          </Box>
                        </Box>
                        ))}
                      </>
                    }
                    <Button
                      onClick={handleClose}>
                      Close
                    </Button>
                  </Box>
                  :
                  <></>
          }
        </Container>
      </Box>
    </Modal>
  )
}

export default ExpensesModal;