import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
  Box,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal, 
  Radio, 
  RadioGroup, 
  Skeleton, 
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import useCurrencyService from '../../../../components/hooks/useCurrencyService';
import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';
import NA from '../../../../components/utils/NA';

import { 
  CreatePayment,
  GetServiceFeePrice,
  GetServicesForProduct,
  // GetDeliveryMethods, 
  // GetDeliveryMethodsPrice, 
} from '../../../../redux/actions/pay/paymentsActions';
import { 
  GetCompanyBankAccounts 
} from '../../../../redux/actions/pay/companyBankAccountsActions';
import { 
  GetHolidays 
} from '../../../../redux/actions/miscellaneousActions';

import "./styles.scss";
import AmountTextField from '../../../../components/textFields/AmountTextField';
import { AddWalletBalance } from '../../../../redux/actions/international/walletActions';

const Data = {
  Date: dayjs().add(1, 'day'),
  PaymentDate: dayjs().add(4, 'day'),
  Price: ""
};

const WalletModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const other = useSelector(state => state.other);
  const companyId = useSelectedCompany();
  const { handleShowAmount, handleCalculateAmount } = useCurrencyService();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingServiceFeePrice, setLoadingServiceFeePrice] = useState(false);
  const [mode, setMode] = useState(-1);
  const [step, setStep] = useState(-1);
  
  const [bankAccountsList, setBankAccountsList] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  const [servicesList, setServicesList] = useState([]);
  const [serviceFees, setServiceFees] = useState([]);

  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const [holidaysList, setHolidaysList] = useState([]);

  const [isPaymentApproved, setIsPaymentApproved] = useState(null);

  const [feeAmount, setFeeAmount] = useState(0);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setFeeAmount(0);
    setMode(-1);
    setStep(-1);
    setSelectedServiceId(null);
    setSelectedService(null);
    setSelectedBankAccount(null);
    setIsPaymentApproved(null);
    setHolidaysList([]);
  };

  useEffect(() => {
    try {
      dispatch(GetHolidays(companyId, setLoading));
      dispatch(GetCompanyBankAccounts(companyId, 1, 1000, setLoading));
      if(open){
        if(update?.defaultMode === 1){
          // mode = 1 -> add money
          setMode(1);
        }else if(update?.defaultMode === 2){
          // mode = 2 -> withdraw money
          setMode(2);
        }else{
          toast.error("Something went wrong!");
          handleClose();
        }
      }
      setStep(1);
    } catch (err) {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatCompanyBankAccountsList(state.companyBankAccountsList.records || []);
      formatServicesList(state.servicesForProductList.records || []);
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, step]);
  
  useEffect(() => {
    try {
      formatHolidaysList(other.holidaysList || []);
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other]);
  
  // --------------- list formatter --------------
  const formatServicesList = (list) => {
    setServicesList(list);
    if(open && step === 3) handleGetServiceFeePrice(list);
  };
  
  const formatCompanyBankAccountsList = (list) => {
    setBankAccountsList(list);
  };

  const formatHolidaysList = (list) => {
    setHolidaysList(list);

    // check if today is valid of not
    let today = dayjs()
    let addDays = 0;
    while(!findValidDebitDate(today, addDays, list)){
      addDays++;
    };
    setFormData({
      ...formData,
      Date: dayjs(today).add(addDays, 'day')
    });
  };

  // --------------- handle change ---------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePrevStep = () => {
    setStep(step-1);
  };

  const handleNextStep = () => {
    setStep(step+1);
  };

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> add money payment
        let obj = {
          "ref_co_bank_acc_id": selectedBankAccount.id,
          "ref_service_id": selectedServiceId,
          'delivery_method': selectedService?.delivery_method?.delivery_method,
          "debit_date": dayjs(formData.Date).format('YYYY-MM-DD'),
          "currency": "USD",
          "amount": handleCalculateAmount("USD", formData.Price)
        };
        dispatch(AddWalletBalance(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleNextStep();
          });
        });
      } else {
        throw new Error();
      }
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  const handleGetServicesForProduct = () => {
    dispatch(GetServicesForProduct(companyId, "International Wallet", setLoading2)).then(() => {
      handleNextStep();
    });   
  };

  // ---------------- miscellaneous ----------------
  const handleGetServiceFeePrice = async (list) => {
    let rate = [];

    for (const service of list) {
      try { 
        let amount = handleCalculateAmount("USD", formData?.Price);
        await dispatch(GetServiceFeePrice(companyId, service.id, amount, setLoadingServiceFeePrice))
          .then(({res, statusCode}) => {
          if(statusCode === 200){
            rate.push(res.data)
          }
        });
      } catch (error) {
        console.error(`Error fetching data for object with id ${service.id}:`, error);
      }
    }

    setServiceFees(rate);
  };

  const handleDeliveryMethod = (method) => {
    switch(method){
      case "INSTANT_TRANSFER": return "Instant Transfer";
      case "SAME_DAY_ACH": return "Same day ACH";
      case "ONE_DAY_ACH": return "One day ACH";
      case "NEXT_DAY_ACH": return "Next day ACH"
      case "STANDARD_ACH": return "Standard ACH";
      case "DOMESTIC_WIRE": return "Domestic wire";

      default: return null;
    }
  };

  function findValidDebitDate(today, addDays, holidays){
    let date = dayjs(today).add(addDays, 'day');
    let isValid = true;

    holidays.forEach((i) => {
      if (i === date.format("YYYY-MM-DD")) {
        isValid = false;
      }
    });

    // to disable all weekends
    if(isValid){
      let currentDay = dayjs(date).day();
      if(currentDay >= 1 && currentDay <= 5 ){
        isValid = true;
      } else {
        isValid = false;
      };
    };

    return isValid;
  };

  const disableSpecificDates = (date) => {
    let disabled = false;

    holidaysList.forEach((i) => {
      if (i.date === date.format("YYYY-MM-DD")) {
        disabled = true;
      }
    });

    // to disable all weekends
    if(!disabled){
      let currentDay = dayjs(date).day();
      if(currentDay >= 1 && currentDay <= 5 ){
        disabled = false;
      } else {
        disabled = true;
      };
    };

    return disabled;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>

        <Box
          className={`fullpage__modal animate__animated animate__fadeIn`}>
            
          <ModalTopBar 
            title={""}
            onBack={(step === 5 || step === 1)
              ? handleClose 
              : handlePrevStep}
            />

          {
            step === 1
            // select bank
            ?
            <Container
              maxWidth="sm">
              <Box
                className="flexCenter_Column"
                gap={"40px"}
                mb={"20px"}>
                <Typography
                  variant='h4'
                  textAlign={"center"}
                  mt={"40px"}>
                  How do you want to add money?
                </Typography> 
                <Box
                  className="flex__Column"
                  width={"100%"}
                  gap={"20px"}>

                  <Typography
                    textAlign={"left"}
                    variant='subtitle2'>
                    Bank accounts
                  </Typography>
                  {
                    loading
                    ?
                    <>
                      <Skeleton
                        variant='rectangle'
                        sx={{ width: "100%", borderRadius: "16px", height: "92.5px",  }}/>
                    </>
                    : 
                      bankAccountsList.length === 0
                      ?
                      <Typography textAlign={"center"}>No bank account found</Typography>
                      :
                        bankAccountsList.map((item, index) => (
                          <Box
                            key={index}
                            className={`flexCenterSBRow ${item?.verified === true ? 'payment__bank' : 'payment__bank-disabled'}  
                              ${selectedBankAccount?.id === item.id && 'payment__bank-selected'}`}
                            onClick={item.verified === true 
                              ? () => setSelectedBankAccount(item)
                              : () => {}}>
                            <Box
                              className="flexCenter_Row" 
                              gap={"20px"}>
                              <AccountBalanceOutlinedIcon 
                                fontSize='large'/>
                              <Box>
                                <Box
                                  className="flexCenter_Row"
                                  gap={"10px"}>
                                  <Typography 
                                    variant='subtitle1'
                                    fontWeight={600}>
                                    Bank Account
                                  </Typography>
                                  {
                                    item.is_default === true
                                    &&
                                    <Chip
                                      color='warning'
                                      size='small'
                                      label={'Primary'} 
                                      />
                                  }
                                  {
                                    item.verified === false
                                    &&
                                    <Chip
                                      color='error'
                                      size='small'
                                      label={'Unverified'} 
                                      />
                                  }
                                </Box>
                                <Typography
                                  variant='body2'
                                  color={"grey"}>
                                  ...{item.account_number.slice(-4)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        ))
                  }
                </Box>
                <LoadingButton
                  variant="contained"
                  disabled={selectedBankAccount === null}
                  loading={loading2}
                  onClick={handleNextStep}>
                  Continue
                </LoadingButton>
              </Box>
            </Container>
            :
              step === 2
              ?
              // select amount
              <Container
                maxWidth="sm">
                <Box
                  className="flexCenter_Column"
                  gap={"30px"}>
                  <Typography
                    variant='h4'
                    textAlign={"center"}
                    mt={"40px"}>
                    Enter amount you want to add.
                  </Typography> 

                  <Box 
                    className="flexFSSBRow"
                    gap="15px"
                    sx={{ width: "300px" }}>
                    <AmountTextField
                      currencyName="Currency"
                      currency={"USD"}
                      onCurrencyChange={handleChange}
                      label="Amount"
                      fullWidth
                      name="Price"
                      value={formData.Price}
                      onChange={handleChange}
                      />
                  </Box>

                  <LoadingButton
                    variant="contained"
                    disabled={!(formData.Price !== "")}
                    onClick={handleGetServicesForProduct}>
                    Continue
                  </LoadingButton>
                </Box>
              </Container>
              :
                step === 3
                // select delivery method
                ?
                <Container
                  maxWidth="sm">
                  <Box
                    className="flexCenter_Column"
                    gap={"30px"}>
                    <Typography
                      variant='h4'
                      textAlign={"center"}
                      mt={"40px"}>
                      Choose payment speed.
                    </Typography> 
                    <Box
                      className="flexCenter_Column"
                      width={"100%"}
                      gap={"10px"}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="create-payment-radio-group"
                          name="radio-buttons-group"
                          value={selectedServiceId}
                          onChange={(e) => {
                            setSelectedServiceId(e.target.value);

                            let serviceTemp = servicesList.find(i => i.id+"" === e.target.value);
                            if(serviceTemp){
                              setSelectedService(serviceTemp);
                              handleChange({ target: {
                                name: "PaymentDate",
                                value: dayjs(formData.Date).add(serviceTemp?.delivery_method?.delivery_speed, 'day')
                              }});
                              setFeeAmount(serviceFees[servicesList.indexOf(serviceTemp)]);
                            }

                          }}>
                          {
                            servicesList.filter(i => {
                              if(i?.delivery_method?.delivery_method === "INSTANT_TRANSFER"){
                                if(dayjs(formData.Date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')){
                                  return true;
                                }else {
                                  return false;
                                }
                              }else {
                                return true;
                              }
                            }).map((item, index) => (
                              <FormControlLabel 
                                key={index}
                                value={item.id}
                                control={<Radio />} 
                                label={
                                  <Box
                                    mt={"10px"}>
                                    <Box className="flexCenter_Row">
                                      <Typography
                                        variant='body2'>
                                        {handleDeliveryMethod(item?.delivery_method?.delivery_method) || <NA />}
                                      </Typography>&nbsp;
                                      {
                                        item?.delivery_method?.delivery_speed > 1
                                        &&
                                        <Chip
                                          size='small'
                                          sx={{ marginLeft: "5px" }}
                                          label={
                                            <>
                                              {item?.delivery_method?.delivery_speed}&nbsp;days
                                            </>
                                          }/>
                                      }
                                    </Box>
                                    {
                                      loadingServiceFeePrice
                                      ?
                                      <Skeleton
                                        variant="text" 
                                        />
                                      :
                                      <Typography
                                        variant='caption'
                                        color={"grey"}>
                                        Fee {handleShowAmount("USD", serviceFees[index])}
                                      </Typography>
                                    }
                                    {/* <Typography variant='caption' color={"grey"}>Free</Typography> */}
                                  </Box>
                                }
                                />

                            ))
                          }
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <LoadingButton
                      variant="contained"
                      disabled={selectedServiceId === null || loadingServiceFeePrice}
                      onClick={handleNextStep}>
                      Continue
                    </LoadingButton>
                  </Box>
                </Container>
                : 
                  step === 4
                  // review & confirm screen
                  ?
                  <Container
                    maxWidth="sm">
                    <Box
                      className="flex__Column"
                      gap={"30px"}>
                      <Typography
                        variant='h4'
                        textAlign={"center"}
                        mt={"40px"}>
                        Review & confirm
                      </Typography> 

                      <Box
                        className="flex__Column"
                        gap={"10px"}>
                        <Box
                          className="flex__Column">
                          <Typography
                            variant='body2'
                            mb={"8px"}
                            fontWeight={500}>
                            Amount to be added
                          </Typography>
                          <Typography
                            ml={"20px"}
                            variant='h6'>
                            {handleShowAmount("USD", 
                              handleCalculateAmount("USD", formData?.Price))}
                          </Typography>
                        </Box>

                        <Divider />

                        <Box
                          className="flex__Column">
                          <Typography
                            variant='body2'
                            mb={"8px"}
                            fontWeight={500}>Add to</Typography>
                          <Box
                            ml={"20px"}
                            className="flexCenterSBRow">
                            <Box
                              className="flex__Column">
                              <Typography
                                variant='subtitle1'>
                                International wallet
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Divider />

                        <Box
                          className="flex__Column">
                          <Typography
                            variant='body2'
                            mb={"8px"}
                            fontWeight={500}>Add from</Typography>
                          <Box
                            ml={"20px"}>
                            <Typography
                              variant='caption'
                              color={"grey"}>
                              Bank account
                            </Typography>
                            {
                              selectedBankAccount
                              &&
                              <Typography
                                variant='subtitle1'>
                                Bank account (...{selectedBankAccount?.account_number?.slice(-4)})
                              </Typography>
                            }
                          </Box>
                          <Box
                            ml={"20px"}>
                            <Typography
                              variant='caption'
                              color={"grey"}>
                              Debit date
                            </Typography>
                            <Typography
                              variant='subtitle1'>
                              {dayjs(formData.Date).format("MMM DD, YYYY")}
                            </Typography>
                          </Box>
                        </Box>

                        <Divider />

                        {/* <Box
                          className="flex__Column">
                          <Typography
                            variant='body2'
                            mb={"8px"}
                            fontWeight={500}>Vendor Receives</Typography>
                          <Box
                            ml={"20px"}>
                            <Typography
                              variant='caption'
                              color={"grey"}>
                              Bank transfer to
                            </Typography>
                            <Typography
                              variant='subtitle1'>
                              Bank account (...{mode === 1
                                ? update?.vendor_bank_account
                                : update?.ref_vendor_id?.associated_vendor_bank_account[0]?.account_number?.slice(-4)})
                            </Typography>
                          </Box>
                          <Box
                            ml={"20px"}>
                            <Typography
                              variant='caption'
                              color={"grey"}>
                              Delivery date
                            </Typography>
                            <Typography
                              variant='subtitle1'>
                              {dayjs(formData.PaymentDate).format("MMM DD, YYYY")}
                            </Typography>
                          </Box>
                        </Box> */}

                        {/* <Divider /> */}

                        {/* <Box
                          className="flex__Column">
                          <Typography
                            variant='caption'>Memo to vendor</Typography>
                          <Typography
                            variant='subtitle1'>No memo added</Typography>
                        </Box>
                        <Divider /> */}

                        <Box
                          className="flex__Column">
                          <Typography
                            variant='body2'
                            mb={"8px"}
                            fontWeight={500}>Transaction fees</Typography>
                          <Box
                            ml={"20px"}
                            className="flexCenterSBRow">
                            <Typography
                              variant='subtitle1'>Total fee</Typography>
                            <Typography
                              variant='subtitle1'>
                              {handleShowAmount("USD", feeAmount)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <LoadingButton
                        variant="contained"
                        loading={loading}
                        onClick={handleSubmit}>
                        Confirm and schedule
                      </LoadingButton>
                    </Box>
                  </Container>
                  :
                    step === 5
                    // scheduled screen
                    ?
                    <Container
                      maxWidth="sm">
                      <Box
                        width={"100%"}
                        margin={"0px 20px"}
                        className="flex__Column"
                        gap={"30px"}>
                        <Typography
                          variant='h4'
                          textAlign={"center"}
                          mt={"40px"}>
                          Transfer scheduled
                        </Typography> 

                        <Box
                          className="flex__Column"
                          gap={"30px"}>
                          <Box
                            className="flex__Column">
                            <Typography
                              variant='body1'
                              fontWeight={500}>Transfer summary</Typography>
                            <Typography
                              ml={"20px"}
                              variant='h6'>
                              {handleShowAmount("USD", 
                                handleCalculateAmount("USD", formData?.Price))}
                            </Typography>
                          </Box>
                          <Box
                            className="flex__Column"
                            gap={"5px"}>
                            {/* <Box  
                              className="flexCenter_Row">
                              <Typography 
                                width={"50%"}
                                color={"grey"}>
                                Pay to vendor
                              </Typography>
                              <Typography 
                                width={"50%"}>
                                {mode === 1
                                  ? update?.vendor_name
                                  : update?.ref_vendor_id?.name}
                              </Typography>
                            </Box> */}
                            {/* <Box  
                              className="flexCenter_Row">
                              <Typography 
                                width={"50%"}
                                color={"grey"}>
                                Payment method
                              </Typography>
                              <Typography 
                                width={"50%"}>
                                Business checking 
                              </Typography>
                            </Box> */}
                            <Box  
                              className="flexCenter_Row">
                              <Typography 
                                width={"50%"}
                                color={"grey"}>
                                Debit date
                              </Typography>
                              <Typography 
                                width={"50%"}>
                                {dayjs(formData.Date).format("MMM DD, YYYY")}
                              </Typography>
                            </Box>
                            <Box  
                              className="flexCenter_Row">
                              <Typography 
                                width={"50%"}
                                color={"grey"}>
                                Delivery method
                              </Typography>
                              <Typography 
                                width={"50%"}>
                                {handleDeliveryMethod(selectedService?.delivery_method?.delivery_method) || <NA />} transfer
                              </Typography>
                            </Box>
                            <Box  
                              className="flexCenter_Row">
                              <Typography 
                                width={"50%"}
                                color={"grey"}>
                                Delivery date
                              </Typography>
                              <Typography 
                                width={"50%"}>
                                {dayjs(formData.PaymentDate).format("MMM DD, YYYY")}
                              </Typography>
                            </Box>
                            <Box  
                              className="flexCenter_Row">
                              <Typography 
                                width={"50%"}
                                color={"grey"}>
                                Fees
                              </Typography>
                              <Typography 
                                width={"50%"}>
                                {handleShowAmount("USD", feeAmount)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <LoadingButton
                          variant="contained"
                          disabled={selectedServiceId === null}
                          onClick={() => {
                            handleClose();
                          }}>
                          Go to wallet
                        </LoadingButton>
                      </Box>
                    </Container>
                    :
                    <></>
          }
        </Box>
      </Box>
    </Modal>
  )
}

export default WalletModal;