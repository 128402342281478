import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Chip,
  Container,
  MenuItem,
  Modal, 
  TextField, 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateEmployee, 
  GetEmployees, 
  UpdateEmployee 
} from '../../../../redux/actions/main/EmployeesActions';

import "./styles.scss";

const employeeRolesList = [
  { label: "Admin", value: "Admin" },
  { label: "Contributor", value: "Contributor" },
  { label: "Accountant", value: "Accountant" },
];

const Data = {
  FirstName: "",
  LastName: "",
  Email: "",
  BranchId: "",
  EmployeeRole: "Contributor"
};

const UsersModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.main);
  const companyId = useSelectedCompany();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);

  const [branchesList, setBranchesList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open) {
      if(update?.defaultMode === 1){
        // mode = 1 - create employee
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - edit employee
        setMode(2);
        setFormData({
          ...Data,
          FirstName: update?.user?.first_name || "",
          LastName: update?.user?.last_name || "",
          Email: update?.user?.user_name || "",
          EmployeeRole: update?.employee_role || "",
          BranchId: update?.branch?.id || ""
        });
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      setBranchesList(state.branchesList.records || []);
    } catch(err) {}
  }, [state]);


  const handleChange = (e) => {
    if(e.target.name === "FirstName" || e.target.name === "LastName"){
      setFormData({ ...formData, [e.target.name]: e.target.value.replace(/[^a-zA-Z]/g, "") });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> create branch
        let obj = {
          "first_name": formData.FirstName,
          "last_name": formData.LastName,
          "email": formData.Email,
          "branch_id": formData.BranchId,
          "employee_role": formData.EmployeeRole
        };
        dispatch(CreateEmployee(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2){
        // mode = 2 -> edit branch
        let obj = {
          // "first_name": formData.FirstName,
          // "last_name": formData.LastName,
          // "email": formData.Email,
          "branch_id": formData.BranchId,
          "employee_role": formData.EmployeeRole,
        };
        dispatch(UpdateEmployee(companyId, update?.guid, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className={`fullpage__modal animate__animated animate__fadeIn`}>
        <ModalTopBar
          onBack={handleClose}
          title={title} 
          />

        <Container 
          maxWidth="sm">
          <Box 
            className="flex__Column"
            gap={"20px"}
            pt="20px">
            {/* {
              mode === 1
              && */}
              <Box>
                <TextField 
                  select
                  label="Location"
                  fullWidth
                  name="BranchId"
                  value={formData.BranchId}
                  onChange={handleChange}
                  >
                  {
                    branchesList.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        <Box className="flexCenter_Row" gap={"10px"}>
                          {item?.display_name}
                          {
                            item.branch_type === "head-office"
                            &&
                            <Chip
                              size='small'
                              color="info"
                              label={"Head office"} 
                              />
                          }
                        </Box>
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Box>
            {/* } */}
            
            <Box 
              className="flexFSSBRow"
              gap="15px">
              <TextField 
                label="First name"
                fullWidth
                name="FirstName"
                disabled={mode === 2}
                value={formData.FirstName}
                onChange={handleChange}
                />
              <TextField 
                label="Last name"
                fullWidth
                name="LastName"
                disabled={mode === 2}
                value={formData.LastName}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexFSSBRow"
              gap="15px">
              <TextField 
                label="Email"
                fullWidth
                disabled={mode === 2}
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexFSSBRow"
              gap="15px">
              <TextField 
                select
                label="Role"
                fullWidth
                name="EmployeeRole"
                value={formData.EmployeeRole}
                onChange={handleChange}
                >
                {
                  employeeRolesList.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Box className="flexCenter_Row" gap={"10px"}>
                        {item?.label}
                      </Box>
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <LoadingButton
              variant="contained"
              loading={loading}
              disabled={mode === 1
              ? !(formData.FirstName !== "" && 
                formData.BranchId !== "" &&
                formData.Email !== "")
              : mode === 2
                ? false
                : true}
              onClick={handleSubmit}>
              {mode === 1 ? "Submit" : "Update"}
            </LoadingButton>
          </Box>
        </Container>
      </Box>
    </Modal>
  )
}

export default UsersModal;