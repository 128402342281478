import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  Button, 
  Chip, 
  CircularProgress, 
  Grid, 
  IconButton, 
  Link,  
  Skeleton,  
  Tooltip,  
  Typography 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import CompanyAvatar from '../../../../components/avatars/CompanyAvatar';
import NA from '../../../../components/utils/NA';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  DeleteBranch,
  GetBranches 
} from '../../../../redux/actions/main/BranchesActions';
import { 
  GetPlanAddons 
} from '../../../../redux/actions/main/PlanAddonsActions';

import { 
  GetMySubscription 
} from '../../../../redux/actions/main/SubscriptionsActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';

import global from "../../../../global.scss";
import  "./styles.scss";

const SubscriptionsPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.main);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(false);
  const [paymentLinkCopied, setPaymentLinkCopied] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null); 

  const [mySubscription, setMySubscription] = useState(null);

  const [planAddonsList, setPlanAddonsList] = useState([]);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    // dispatch(GetPlanAddons(1, 1000, "", setLoading));
    dispatch(GetMySubscription(companyId, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      // setPlanAddonsList(state.planAddonsList.records || []);
      formatMySubscriptionObj(state.mySubscription || null);
    } catch (err) {}
  }, [state]);

  // ----------------- list formatter -----------------
  const formatMySubscriptionObj = (obj) => {
    setMySubscription(obj);
  };

  return (
    <Box
      sx={{ height: "calc(100vh - 200px)"}}>

      <Box
        className="subs__top">
        <Typography
          variant='subtitle2'
          mb={"20px"}>
          Your Subscription Plan
        </Typography>
        {
          loading
          ?
          <Skeleton />
          :
            mySubscription
            ?
            <Box
              className="subs__my-subs flexCenterCenterRow"
              sx={{ padding: "20px" }}>
              <Typography
                variant='subtitle2'
                color={"grey"}>
                Current plan: 
              </Typography>
              <Typography
                variant='subtitle2'>
                {mySubscription.plan_name}&nbsp;
                ({mySubscription.plan_type})
              </Typography>
            </Box>
            :
            <Box
              className="subs__my-subs flexCenterCenterRow"
              sx={{ padding: "20px" }}>
              <NA 
                label={"No subscription found"}/>
            </Box>
        }


      </Box>


      {/* <Box
        className="subs__bottom">
        <Typography
          variant='subtitle2'
          mb={"20px"}>
          Plan Addons
        </Typography>
        {
          loading
          ?
          <Skeleton />
          :
            planAddonsList.length === 0
            ?
            <NA 
              label={"No plan addon found"} />
            :
              <Grid
                container
                spacing={2}>
                { 
                  planAddonsList.map((addon, addonIndex) => (
                    <Grid
                      item
                      xs={6} sm={4} md={3} lg={3}
                      key={addonIndex}>
                      <Box
                        className="subs__addon-card flex__Column"
                        gap={"5px"}>
                        <Typography
                          variant='subtitle1'>
                          {addon.addon_name || <NA />}
                        </Typography>
                        <Typography
                          variant='body2'
                          color={"grey"}
                          mb="15px">
                          {addon.addon_description || <NA />}
                        </Typography>

                      
                        <Box
                          class="flexCenterFERow">
                          <Chip
                            color={'info'}
                            size='small'
                            label={addon?.subscription_plan?.plan_name || <NA />}
                            />
                        </Box>
                      </Box>
                    </Grid>
                 ))
                }
              </Grid>
          // {
          //   "id": 1,
          //   "addon_name": "Currency Wallet",
          //   "addon_description": "Currency Wallet",
          //   "one_time_setup_fee": 20,
          //   "monthly_fee": 3,
          //   "yearly_fee": 36,
          //   "currency": "USD",
          //   "ref_plan_id": 1,
          //   "is_active": true,
          //   "subscription_plan": {
          //       "id": 1,
          //       "plan_name": "Standard"
          //   }
          // }
        }
      </Box> */}
    </Box>
  )
}

export default SubscriptionsPage