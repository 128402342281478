import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Modal, 
  Select, 
  TextField, 
  Link,
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateCostAccount,
  UpdateCostAccount
} from '../../../../redux/actions/expense/costAccountsActions';
import { 
  DeleteCostObject, 
  GetCostObjects 
} from '../../../../redux/actions/expense/costObjectsActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import CreateCostObjectsModal from './CreateCostObjectsModal';

import "./styles.scss";

const Data = {
  CostObjectId: "",
  CostAccount: ""
};

const CostAccountsModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.expense);
  const companyId = useSelectedCompany();
  const costObjectInputFieldRef = useRef(null);
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(-1);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [createCostObjectModalOpen, setCreateCostObjectModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const [costObjectsList, setCostObjectsList] = useState([]);

  const [showDeleteBtn, setShowDeleteBtn] = useState(true);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setMode(-1);
  };

  useEffect(() => {
    if(open) {
      if(update?.defaultMode === 1){
        // mode = 1 - create cost account
        setMode(1);
      }else if(update?.defaultMode === 2){
        // mode = 2 - edit cost account
        setMode(2);
        setFormData({
          ...Data,
          CostObjectId: update?.ref_cost_object_id?.id || "",
          CostAccount: update?.name || "",
        });
      }else{
        toast.error("Something went wrong!");
        handleClose();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatCostObjectsList(state.costObjectsList || []);
    } catch (err) {}
  }, [state]);

  const formatCostObjectsList = (list) => {
    setCostObjectsList(list);
  }; 

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1){
        // mode = 1 -> create cost account
        let obj = {
          "name": formData.CostAccount || "",
          "ref_cost_object_id": formData.CostObjectId || "",
        };
        dispatch(CreateCostAccount(companyId, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else if(mode === 2){
        // mode = 2 -> edit cost account
        let obj = {
          "name": formData.CostAccount || "",
          "ref_cost_object_id": formData.CostObjectId || "",
        };
        dispatch(UpdateCostAccount(companyId, update?.id, obj, setLoading)).then(() => {
          handleGet(setLoading).then(() => {
            handleClose();
          })
        });
      } else {
        throw new Error();
      };
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  const handleDeleteCostObject = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteCostObject(companyId, deleteId, setDeleting)).then(() => {
      dispatch(GetCostObjects(companyId, 1, 1000, setDeleting)).then(() => {
        setDeleteId(null);
      })
    });
  };

  return (
    <>

    <CreateCostObjectsModal
      open={createCostObjectModalOpen}
      setOpen={setCreateCostObjectModalOpen}
      title={"Create cost object"}
      onCreate={(e) => {
        console.log(e)
        setFormData({
          ...formData, 
          CostObjectId: e?.data?.id || ""
        });
      }}
      />

    <ConfirmationModal 
      open={deleteConfirmationModalOpen}
      setOpen={setDeleteConfirmationModalOpen}
      title={"Delete " + modalTitle}
      onCancel={() => setDeleteId(null)}
      onConfirm={handleDeleteCostObject}
      />

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        className={`fullpage__modal animate__animated animate__fadeIn`}>
          
        <ModalTopBar
          onBack={handleClose}
          title={title} 
          />

        <Container 
          maxWidth="sm">
          <Box 
            className="flex__Column"
            gap={"20px"}
            pt="20px">
            <FormControl>
            <InputLabel id="cost-object-select-label">Cost object</InputLabel>
            <Select 
              select
              ref={costObjectInputFieldRef}
              labelId="cost-object-select-label"
              label="Cost object"
              fullWidth
              name="CostObjectId"
              value={formData.CostObjectId}
              onChange={handleChange}
              onOpen={() => setShowDeleteBtn(true)}
              onClose={() => setShowDeleteBtn(false)}
              >
                {
                  costObjectsList.length === 0
                  ?
                    <MenuItem disabled>
                      No cost object found
                    </MenuItem>
                  :
                  costObjectsList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      <Box 
                        className="flexCenterSBRow" 
                        gap={"10px"}
                        width={"100%"}>
                        {item?.name || ""}
                        {/* {
                          showDeleteBtn
                          &&
                            item.id === deleteId && deleting
                            ?
                            <CircularProgress size={26} sx={{ margin: "7px" }}/>
                            :
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setModalTitle(item.name || "");
                                setDeleteId(item.id);
                                setDeleteConfirmationModalOpen(!deleteConfirmationModalOpen);
                              }}> 
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        } */}
                      </Box>
                    </MenuItem>
                  ))
                }
                <MenuItem
                  value={""}>
                  <Link
                    onClick={() => {
                      setCreateCostObjectModalOpen(!createCostObjectModalOpen);
                    }}>
                    Create new cost object
                  </Link>
                </MenuItem>
            </Select>
            </FormControl>
          
            <TextField 
              label="Cost account name"
              fullWidth
              name="CostAccount"
              value={formData.CostAccount}
              onChange={handleChange}
              />
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleSubmit}>
              {mode === 1 ? "Submit" : "Update"}
            </LoadingButton>
          </Box>
        </Container>
      </Box>
    </Modal>
    </>
  )
}

export default CostAccountsModal;