import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
  Box,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Modal, 
  Radio, 
  RadioGroup, 
  Skeleton, 
  TextField, 
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers';

import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import useCurrencyService from '../../../components/hooks/useCurrencyService';
import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../components/buttons/Button';
import NA from '../../../components/utils/NA';

import { 
  CreateInternationalPayment,
  GetServiceFeePrice,
  GetServicesForProduct,
  // GetDeliveryMethods, 
  // GetDeliveryMethodsPrice, 
} from '../../../redux/actions/pay/paymentsActions';
import { 
  GetWalletBalance 
} from '../../../redux/actions/international/walletActions';
import { 
  GetPurposeCodes 
} from '../../../redux/actions/international/referenceActions';

import "./styles.scss";

const Data = {
  Date: dayjs(),
  PaymentDate: dayjs().add(4, 'day'),
};

let purpose_code_currencies = ["BHD", "CNY", "INR", "MYR", "AED"];

const paymentSpeedList = [
  { label: "Regular", value: { currency: "USD", amount: 300 }},
  { label: "Priority", value: { currency: "USD", amount: 2000 }},
]

const CreateInternationalPaymentModal = ({
  open, 
  setOpen,
  title,
  update,
  handleGet
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(state => state.pay);
  const other = useSelector(state => state.other);
  const international = useSelector(state => state.international);
  const companyId = useSelectedCompany();
  const { handleShowAmount, handleCalculateAmount } = useCurrencyService();
  const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingServiceFeePrice, setLoadingServiceFeePrice] = useState(false);
  const [mode, setMode] = useState(-1);
  const [step, setStep] = useState(-1);

  const [wallet, setWallet] = useState(null);

  const [servicesList, setServicesList] = useState([]);
  const [serviceFees, setServiceFees] = useState([]);

  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const [isPaymentApproved, setIsPaymentApproved] = useState(null);

  const [feeAmount, setFeeAmount] = useState(0);

  const [purposeCodesList, setPurposeCodesList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setFormData(Data);
    setFeeAmount(0);
    setMode(-1);
    setStep(-1);
    setSelectedServiceId(null);
    setSelectedService(null);
    setIsPaymentApproved(null);
  };

  useEffect(() => {
    try {
      if(open){
        handleGetBalance();
        if(update?.defaultMode === 1){
          // mode = 1 -> Create international payment - in flow
          setMode(1);
        }else if(update?.defaultMode === 2){
          // mode = 2 -> Create international payment - after bill create
          setMode(2);
        }else{
          toast.error("Something went wrong!");
          handleClose();
        }
      }
      setStep(1);
    } catch (err) {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    try {
      formatServicesList(state.servicesForProductList.records || []);
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, step]);

  useEffect(() => {
    try {
      setWallet(international.walletBalance || null);
    } catch (err) {}  
  }, [international]);
  
  // --------------- list formatter --------------
  const formatServicesList = (list) => {
    setServicesList(list);
    if(open && step === 2) handleGetServiceFeePrice(list);
  };

  // --------------- handle change ---------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePrevStep = () => {
    setStep(step-1);
  };

  const handleNextMode = () => {
    setStep(step+1);
  };

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
      if(mode === 1 || mode === 2){
        // mode = 1 -> create international payment
        let obj = {
          "ref_bill_id": update?.bill_id,
          // "ref_co_bank_acc_id": "",
          // "ref_service_id": "",
          'delivery_method': selectedService.label,
          "debit_date": dayjs(formData.Date).format('YYYY-MM-DD'),
        };
        dispatch(CreateInternationalPayment(companyId, obj, setLoading)).then(({ res, statusCode }) => {
          if(statusCode === 201){
            setIsPaymentApproved(res.data.status);
            handleGet(setLoading).then(() => {
              handleNextMode();
            });
          }
        });
      } else {
        throw new Error();
      }
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  const handleGetServicesForProduct = () => {
    if(purpose_code_currencies.includes(update?.currency)){
      handlePurposeCodes(setLoading2);
    }

    dispatch(GetServicesForProduct(companyId, "Pay", setLoading2)).then(() => {
      handleNextMode();
    });   
  };

  async function handleGetBalance(customLoading){
    dispatch(GetWalletBalance(companyId, customLoading ? customLoading : setLoading));
  };  

  async function handlePurposeCodes(customLoading){
    let query = "";
    query += `&currency=${update?.currency}`;
    query += `&entity_type=company`;
    query += `&bank_account_country=${update?.ref_vendor_id?.associated_international_vendor[0]?.bank_country}`;
    dispatch(GetPurposeCodes(companyId, query, customLoading ? customLoading : setLoading)).then(({ res, statusCode }) => {
      if(statusCode === 200){
        setPurposeCodesList(res.data.purposeCodes || []);
      }
    })
  };  

  // ---------------- miscellaneous ----------------
  const handleGetServiceFeePrice = async (list) => {
    let rate = [];

    for (const service of list) {
      try { 
        await dispatch(GetServiceFeePrice(companyId, service.id, update?.total_amount, setLoadingServiceFeePrice))
          .then(({res, statusCode}) => {
          if(statusCode === 200){
            rate.push(res.data)
          }
        });
      } catch (error) {
        console.error(`Error fetching data for object with id ${service.id}:`, error);
      }
    }

    setServiceFees(rate);
  };

  const handleDeliveryMethod = (method) => {
    switch(method){
      case "INSTANT_TRANSFER": return "Instant Transfer";
      case "SAME_DAY_ACH": return "Same day ACH";
      case "ONE_DAY_ACH": return "One day ACH";
      case "NEXT_DAY_ACH": return "Next day ACH"
      case "STANDARD_ACH": return "Standard ACH";
      case "DOMESTIC_WIRE": return "Domestic wire";

      default: return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>

        <Box
          className={`fullpage__modal animate__animated animate__fadeIn`}>
            
          <ModalTopBar 
            title={""}
            onBack={(step === 4 || step === 1)
              ? handleClose 
              : handlePrevStep}
            />

          {
            step === 1
            // select wallet
            ?
            <Container
              maxWidth="sm">
              <Box
                className="flexCenter_Column"
                gap={"40px"}
                mb={"20px"}>
                <Typography
                  variant='h4'
                  textAlign={"center"}
                  mt={"40px"}>
                  How do you want to pay?
                </Typography> 

                {
                  mode === 1
                  ?
                  <Typography
                    textAlign={"center"}>
                     <b>{handleShowAmount(update?.base_currency, update?.base_amount)}</b>
                    &nbsp;to&nbsp;
                    <b>{update?.vendor_name}</b>
                  </Typography> 
                  :
                    mode === 2
                    ? 
                    <Typography
                      textAlign={"center"}>
                      <b>{handleShowAmount(update?.base_currency, update?.base_amount)}</b>
                      &nbsp;to&nbsp;
                      <b>{update?.ref_vendor_id?.name}</b>
                    </Typography> 
                    :
                        <></>
                }

                <Box
                  className="flex__Column"
                  width={"100%"}
                  gap={"20px"}>

                  <Typography
                    textAlign={"left"}
                    variant='subtitle2'>
                    International wallet
                  </Typography>
                  {
                    loading
                    ?
                    <>
                      <Skeleton
                        variant='rectangle'
                        sx={{ width: "100%", borderRadius: "16px", height: "92.5px",  }}/>
                    </>
                    : 
                      <Box
                        className={`flexCenterSBRow 
                            ${update?.base_amount <= handleCalculateAmount(wallet?.currency, wallet?.amount) ? 'payment__international-wallet' : 'payment__international-wallet-disabled'}`}
                          >
                        <Box
                          className="flexCenter_Row" 
                          gap={"20px"}>
                          <AccountBalanceWalletOutlinedIcon 
                            fontSize='large'/>
                          <Box>
                            <Box
                              className="flexCenter_Row"
                              gap={"10px"}>
                              <Typography 
                                variant='body1'
                                fontWeight={500}
                                color={"grey"}>
                                Balance
                              </Typography>
                            </Box>
                            <Typography 
                              variant='subtitle1'
                              fontWeight={600}>
                              {handleShowAmount(wallet?.currency, 
                                handleCalculateAmount(wallet?.currency, wallet?.amount))}
                            </Typography>
                          </Box>
                        </Box>
                          {
                            update?.base_amount > handleCalculateAmount(wallet?.currency, wallet?.amount)
                            &&
                            <Chip 
                              label={"Insufficient Balance"}
                              color='error'
                              // size='small'
                              />
                          }
                      </Box>
                  }
                </Box>
                <LoadingButton
                  variant="contained"
                  disabled={update?.base_amount > handleCalculateAmount(wallet?.currency, wallet?.amount) || loading}
                  loading={loading2}
                  onClick={handleGetServicesForProduct}>
                  Continue
                </LoadingButton>
              </Box>
            </Container>
            :
              step === 2
              // select other options and show fees
              ?
              <Container
                maxWidth="sm">
                <Box
                  className="flexCenter_Column"
                  gap={"30px"}>
                  <Typography
                    variant='h4'
                    textAlign={"center"}
                    mt={"40px"}>
                    Payment details
                  </Typography> 

                  {/* <Typography
                    textAlign={"center"}>
                    Choose the date for your payment to go out.
                  </Typography>  */}

                  <Box
                    className="flexCenter_Column"
                    width={"100%"}
                    gap={"10px"}>
                    {
                      purpose_code_currencies.includes(update?.currency)
                      &&
                      <Box  
                        width={"340px"}
                        className="flexCenterCenterRow">
                        <TextField
                          select
                          label="Payment purpose"
                          name="PurposeCode"
                          fullWidth
                          onChange={handleChange}>
                          {
                            purposeCodesList.map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item.purposeCode}>
                                {item.purposeDescription}
                              </MenuItem>
                            ))
                          }
                        </TextField>
                      </Box>
                    }

                    <FormControl>
                      <FormLabel id="create-payment-radio-group">
                        Choose payment speed
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="create-payment-radio-group"
                        name="radio-buttons-group"
                        value={selectedServiceId}
                        onChange={(e) => {
                          setSelectedServiceId(e.target.value);

                          let serviceTemp = paymentSpeedList.find(i => i.label === e.target.value);
                          if(serviceTemp){
                            setSelectedService(serviceTemp);
                            setFeeAmount(serviceTemp.value.amount);
                          }

                        }}>
                        {
                          paymentSpeedList.filter(i => 
                            JSON.parse(update?.ref_vendor_id?.associated_international_vendor[0]?.payment_types).includes(i.label.toLowerCase())
                          ).map((item, index) => (
                            <FormControlLabel 
                              key={index}
                              value={item.label}
                              control={<Radio />} 
                              label={
                                <Box
                                  mt={"10px"}>
                                  <Box className="flexCenter_Row">
                                    <Typography
                                      variant='body2'>
                                      {item.label}
                                    </Typography>&nbsp;
                                  </Box>
                                  {
                                    loadingServiceFeePrice
                                    ?
                                    <Skeleton
                                      variant="text" 
                                      />
                                    :
                                    <Typography
                                      variant='caption'
                                      color={"grey"}>
                                      Fee {handleShowAmount("USD", item.value.amount)}
                                    </Typography>
                                  }
                                </Box>
                              }
                              />

                          ))
                        }
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <LoadingButton
                    variant="contained"
                    disabled={selectedServiceId === null || loadingServiceFeePrice}
                    onClick={handleNextMode}>
                    Continue
                  </LoadingButton>
                </Box>
              </Container>
              : 
                step === 3
                // review & confirm screen
                ?
                <Container
                  maxWidth="sm">
                  <Box
                    className="flex__Column"
                    gap={"30px"}>
                    <Typography
                      variant='h4'
                      textAlign={"center"}
                      mt={"40px"}>
                      Review & confirm
                    </Typography> 

                    <Box
                      className="flex__Column"
                      gap={"10px"}>
                      <Box
                        className="flex__Column">
                        <Typography
                          variant='body2'
                          mb={"8px"}
                          fontWeight={500}>
                          Payment amount
                        </Typography>
                        <Typography
                          ml={"20px"}
                          variant='h6'>
                          {handleShowAmount(update?.base_currency, update?.base_amount)}
                        </Typography>
                        {
                          update?.base_currency !== update?.currency
                          &&
                          <Typography
                            ml={"20px"}
                            variant='subtitle2'
                            color={"grey"}>
                            {handleShowAmount(update?.currency, update?.total_amount)}
                          </Typography>
                        }
                      </Box>

                      <Divider />

                      <Box
                        className="flex__Column">
                        <Typography
                          variant='body2'
                          mb={"8px"}
                          fontWeight={500}>Pay to</Typography>
                        <Box
                          ml={"20px"}
                          className="flexCenterSBRow">
                          <Box
                            className="flex__Column">
                            <Typography
                              variant='caption'
                              color={"grey"}>Vendor's business name</Typography>
                            <Typography
                              variant='subtitle1'>
                              {mode === 1
                                ? update?.vendor_name
                                : update?.ref_vendor_id?.name}
                            </Typography>
                          </Box>
                          <Box
                            className="flex__Column">
                            <Typography
                              variant='caption'
                              color={"grey"}>Invoice #</Typography>
                            <Typography
                              variant='subtitle1'>
                              {(update?.invoice_number && update?.invoice_number !== "NA")
                                ? "#"+ update?.invoice_number
                                : <i style={{ color: "grey" }}>No invoice #</i>}
                              </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Divider />

                      <Box
                        className="flex__Column">
                        <Typography
                          variant='body2'
                          mb={"8px"}
                          fontWeight={500}>Pay from</Typography>
                        <Box
                          ml={"20px"}>
                          <Typography
                            variant='subtitle1'>
                            International wallet
                          </Typography>
                          {/* {
                            selectedBankAccount
                            &&
                            <Typography
                              variant='subtitle1'>
                              Bank account (...{selectedBankAccount?.account_number?.slice(-4)})
                            </Typography>
                          } */}
                        </Box>
                        <Box
                          ml={"20px"}>
                          <Typography
                            variant='caption'
                            color={"grey"}>
                            Debit date
                          </Typography>
                          <Typography
                            variant='subtitle1'>
                            {dayjs(formData.Date).format("MMM DD, YYYY")}
                          </Typography>
                        </Box>
                      </Box>

                      <Divider />

                      {/* <Box
                        className="flex__Column">
                        <Typography
                          variant='body2'
                          mb={"8px"}
                          fontWeight={500}>Vendor Receives</Typography>
                        <Box
                          ml={"20px"}>
                          <Typography
                            variant='caption'
                            color={"grey"}>
                            Bank transfer to
                          </Typography>
                          <Typography
                            variant='subtitle1'>
                            Bank account (...{mode === 1
                              ? update?.vendor_bank_account
                              : update?.ref_vendor_id?.associated_vendor_bank_account[0]?.account_number?.slice(-4)})
                          </Typography>
                        </Box>
                        <Box
                          ml={"20px"}>
                          <Typography
                            variant='caption'
                            color={"grey"}>
                            Delivery date
                          </Typography>
                          <Typography
                            variant='subtitle1'>
                            {dayjs(formData.PaymentDate).format("MMM DD, YYYY")}
                          </Typography>
                        </Box>
                      </Box>

                      <Divider /> */}

                      {/* <Box
                        className="flex__Column">
                        <Typography
                          variant='caption'>Memo to vendor</Typography>
                        <Typography
                          variant='subtitle1'>No memo added</Typography>
                      </Box>
                      <Divider /> */}

                      <Box
                        className="flex__Column">
                        <Typography
                          variant='body2'
                          mb={"8px"}
                          fontWeight={500}>Transaction fees</Typography>
                        <Box
                          ml={"20px"}
                          className="flexCenterSBRow">
                          <Typography
                            variant='subtitle1'>Total fee</Typography>
                          <Typography
                            variant='subtitle1'>
                            {handleShowAmount("USD", feeAmount)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <LoadingButton
                      variant="contained"
                      loading={loading}
                      onClick={handleSubmit}>
                      Confirm and schedule payment
                    </LoadingButton>
                  </Box>
                </Container>
                :
                  step === 4
                  // scheduled screen
                  ?
                  <Container
                    maxWidth="sm">
                    <Box
                      width={"100%"}
                      margin={"0px 20px"}
                      className="flex__Column"
                      gap={"30px"}>
                      <Typography
                        variant='h4'
                        textAlign={"center"}
                        mt={"40px"}>
                        Payment scheduled
                      </Typography> 

                      <Box
                        className="flex__Column"
                        gap={"30px"}>
                        <Box
                          className="flex__Column">
                          <Typography
                            variant='body1'
                            fontWeight={500}>Payment summary</Typography>
                          <Typography
                            ml={"20px"}
                            variant='h6'>
                            {handleShowAmount(update?.currency, update?.total_amount)}
                          </Typography>
                        </Box>
                        <Box
                          className="flex__Column"
                          gap={"5px"}>
                          <Box  
                            className="flexCenter_Row">
                            <Typography 
                              width={"50%"}
                              color={"grey"}>
                              Pay to vendor
                            </Typography>
                            <Typography 
                              width={"50%"}>
                              {mode === 1
                                ? update?.vendor_name
                                : update?.ref_vendor_id?.name}
                            </Typography>
                          </Box>
                          <Box  
                            className="flexCenter_Row">
                            <Typography 
                              width={"50%"}
                              color={"grey"}>
                              Payment method
                            </Typography>
                            <Typography 
                              width={"50%"}>
                              Business checking 
                            </Typography>
                          </Box>
                          <Box  
                            className="flexCenter_Row">
                            <Typography 
                              width={"50%"}
                              color={"grey"}>
                              Debit date
                            </Typography>
                            <Typography 
                              width={"50%"}>
                              {dayjs(formData.Date).format("MMM DD, YYYY")}
                            </Typography>
                          </Box>
                          <Box  
                            className="flexCenter_Row">
                            <Typography 
                              width={"50%"}
                              color={"grey"}>
                              Delivery method
                            </Typography>
                            <Typography 
                              width={"50%"}>
                              {handleDeliveryMethod(selectedService?.delivery_method?.delivery_method) || <NA />} transfer to 
                              (...{mode === 1
                              ? update?.vendor_bank_account
                              : update?.ref_vendor_id?.associated_vendor_bank_account[0]?.account_number?.slice(-4)})
                            </Typography>
                          </Box>
                          <Box  
                            className="flexCenter_Row">
                            <Typography 
                              width={"50%"}
                              color={"grey"}>
                              Delivery date
                            </Typography>
                            <Typography 
                              width={"50%"}>
                              {dayjs(formData.PaymentDate).format("MMM DD, YYYY")}
                            </Typography>
                          </Box>
                          <Box  
                            className="flexCenter_Row">
                            <Typography 
                              width={"50%"}
                              color={"grey"}>
                              Fees
                            </Typography>
                            <Typography 
                              width={"50%"}>
                              {handleShowAmount("USD", feeAmount)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      {
                        isPaymentApproved
                        &&
                        <Box
                          sx={{
                            padding: "20px",
                            borderRadius: "12px",
                            backgroundColor: isPaymentApproved === "Scheduled" ? "#d7ebce" : "#fce2ba"
                          }}
                          className="flexCenter_Row"
                          gap={"20px"}>
                          {
                            isPaymentApproved === "Scheduled"
                            ?
                            <>
                              <TaskAltIcon 
                                color='success'
                                />
                              <Typography
                                color={"#44712e"}
                                fontWeight={500}>
                                Payment approved
                              </Typography>
                            </>
                            :
                            <>
                              <ErrorOutlineIcon 
                                sx={{ color: "#915907" }}
                                />
                              <Typography
                                color={"#915907"}
                                fontWeight={500}>
                                Payment requires approval by&nbsp;{dayjs(formData.Date).format("MMM DD, YYYY")}
                              </Typography>
                            </>
                          }

                        </Box>
                      }

                      <LoadingButton
                        variant="contained"
                        disabled={selectedServiceId === null}
                        onClick={() => {
                          navigate("/pay/payments");
                          handleClose();
                        }}>
                        Go to payments
                      </LoadingButton>
                    </Box>
                  </Container>
                  :
                  <></>
          }
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateInternationalPaymentModal;