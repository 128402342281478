import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Box,
  Container,
  Grid,
  MenuItem,
  Modal, 
  TextField,
  Typography, 
} from '@mui/material';

import useSelectedCompany from '../../../components/hooks/useSelectedCompany';
import useCurrencyService from '../../../components/hooks/useCurrencyService';
import ModalTopBar from '../../../components/topBar/ModalTopBar';
import LoadingButton from '../../../components/buttons/Button';
import NA from '../../../components/utils/NA';

import { 
  AddSubscription,
  GetSubscriptions 
} from '../../../redux/actions/main/SubscriptionsActions';
import { 
  GetCompanyInfo 
} from '../../../redux/actions/dashboard/companyRoleActions';

import "./styles.scss";

const AddSubscriptionPage = () => {
  const navigate = useNavigate();
	const dispatch = useDispatch();
  const state = useSelector(state => state.main);
  const companyId = useSelectedCompany();
  const { handleShowAmount } = useCurrencyService();
  // const [formData, setFormData] = useState(Data);
  const [loading, setLoading] = useState(false);
  const [loadingSubs, setLoadingSubs] = useState(false);

  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  useEffect(() => {
    setSelectedSubscription(null);
    dispatch(GetSubscriptions(1, 20, "&field=monthly_fee&order=asc", setLoadingSubs));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      formatSubscriptionsList(state.subscriptionsList.records || []);
    } catch (err) {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const formatSubscriptionsList = (list) => {
    setSubscriptionsList(list);
  };

  // --------------- handle create ---------------
  const handleSubmit = () => {
    try {
			let obj = {
				"plan_id": selectedSubscription
			};
			dispatch(AddSubscription(companyId, obj, setLoading)).then(() => {
        dispatch(GetCompanyInfo(companyId, setLoading)).then(async ({ res, statusCode }) => {
          if(statusCode === 200){
            if(res.data.company_bank_account_exists === false){
              navigate("/add-bank-account");
            } else if (res.data.company_has_subs === false){
              navigate("/add-subscription");
            } else {
              navigate("/");
            }
          } else {
  
          }
        });
			});
    } catch(err) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Box>
                 
      <ModalTopBar
        onBack={() => navigate("/dashboard")}
        title={"Add a subscription plan"} 
        />

        <Container
          maxWidth="md">
          <Box 
            className="flex__Column"
            gap={"40px"}
            pt="20px">

            <Grid
              container
              className="flexCenterCenterRow"
              spacing={3}>
              {
                subscriptionsList.length === 0
                ?
                <NA 
                  label={"No subscription found"} />
                :
                  subscriptionsList.map((subs, subsIndex) => (
                    <Grid
                      item
                      key={subsIndex}
                      sm={12} md={6} lg={6} xl={6}>
                        {/* {
    "id": 1,
    "plan_name": "Standard",
    "monthly_fee": 1500,
    "yearly_fee": 15000,
    "currency": "USD",
    "num_of_free_exp_users": 3,
    "extra_exp_user_monthly_fee": 500
} */}
                      <Box
                        className={`flex__Column subscription__card ${selectedSubscription === subs.id && 'subscription__card-selected'}`}
                        gap={"40px"}
                        onClick={() => setSelectedSubscription(subs.id)}>
                        <Typography
                          variant='h3'
                          textAlign={"center"}>
                          {subs.plan_name}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          textAlign={"center"}>
                          Monthly: {handleShowAmount(subs.currency, subs.monthly_fee)}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          textAlign={"center"}>
                          Yearly: {handleShowAmount(subs.currency, subs.yearly_fee)}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
              }

            </Grid>

            {
              !loadingSubs
              &&
              <Box
                sx={{ margin: "auto", 
                  width: "300px"
                }}>
                <LoadingButton
                  sx={{ width: "100%"}}
                  disabled={!selectedSubscription}
                  variant="contained"
                  loading={loading}
                  onClick={handleSubmit}>
                  Submit
                </LoadingButton>
              </Box>
            }
          </Box>
        </Container>
    </Box>
  )
}

export default AddSubscriptionPage;