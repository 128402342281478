import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { 
  Box, 
  Button,  
  Divider, 
  IconButton, 
  Typography
} from '@mui/material';

import useCompanyRole from '../hooks/useCompanyRole';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';

import CreatePopover from '../popover/CreatePopover';

import global from "../../global.scss";
import "./styles.scss";

const SideNavigation = ({
  currentPage,
  isNavExpanded,
  setIsNavExpanded,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const companyRole = useCompanyRole();
  const [showNavExpandBtn, setShowNavExpandBtn] = useState(false);

  const [createMenuOpen, setCreateMenuOpen] = useState(false);
  const [createMenuAnchor, setCreateMenuAnchor] = useState(null);

  console.log(companyRole, "companyRole")

  return (
    <Box 
      className={`nav__side ${isNavExpanded ? 'nav__side-expanded' : 'nav__side-collapsed'}`}
      onMouseEnter={() => {
        setShowNavExpandBtn(true);
      }}
      onMouseLeave={() => {
        setShowNavExpandBtn(false);
      }}
      display={"flex"} 
      flexDirection={"column"} 
      gap={"10px"}
      justifyContent={"space-between"}>
      <CreatePopover
        open={createMenuOpen}
        anchor={createMenuAnchor} 
        onClose={() => {
          setCreateMenuOpen(false);
        }}
        />

      {
        location.pathname.split("/")[1] === "profile"
        ?
        <Box className="nav__side-conatiner">
            <Box className={`nav__side-item flexCenter_Row`}
              onClick={() => {
                navigate("/");
              }}>
              <ArrowBackOutlinedIcon />
              {
                isNavExpanded && 
                <Typography variant='body2'>
                  Back
                </Typography>
              }
            </Box>
            <Divider />
            <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
              onClick={() => {
                navigate("/");
              }}>
              <AccountCircleOutlinedIcon />
              {
                isNavExpanded && 
                <Typography variant='body2'>
                  Profile
                </Typography>
              }
            </Box>
        </Box>
        :
          location.pathname.split("/")[1] === "settings"
          ?
          <Box className="nav__side-conatiner">
            <Box className={`nav__side-item  ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'} `}
              onClick={() => navigate("/")}>
              <ArrowBackOutlinedIcon 
                sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
              {
                isNavExpanded && 
                <Typography 
                  variant='body2'
                  fontWeight={600}
                  sx={{ color: global['main_text'] }}>
                  Back
                </Typography>
              }
            </Box>
            <Box className={`${currentPage === 0 ? 'nav__side-selected-admin' : 'nav__side-item-admin'}
              ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
              onClick={() => navigate("/settings")}>
              <BusinessIcon 
                sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
              {
                isNavExpanded && 
                <Typography 
                  variant='body2'
                  fontWeight={600}
                  sx={{ color: currentPage === 0 ? global['purple'] : global['main_text'] }}>
                  Main
                </Typography>
              }
            </Box>
            <Box className={`${currentPage === 1 ? 'nav__side-selected-admin' : 'nav__side-item-admin'}
              ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
              onClick={() => navigate("/settings/pay")}>
              <PaymentOutlinedIcon 
                sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
              {
                isNavExpanded && 
                <Typography 
                  variant='body2'
                  fontWeight={600}
                  sx={{ color: currentPage === 1 ? global['purple'] : global['main_text'] }}>
                  Pay
                </Typography>
              }
            </Box>
            <Box className={`${currentPage === 2 ? 'nav__side-selected-admin' : 'nav__side-item-admin'} 
              ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
              onClick={() => navigate("/settings/receive")}>
              <LocalAtmOutlinedIcon
                sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
              {
                isNavExpanded && 
                <Typography 
                  variant='body2'
                  fontWeight={600}
                  sx={{ color: currentPage === 2 ? global['purple'] : global['main_text'] }}>
                  {/* Receive */}
                  Get Paid
                  {/* Receivables */}
                </Typography>
              }
            </Box>
            <Box className={`${currentPage === 3 ? 'nav__side-selected-admin' : 'nav__side-item-admin'} 
              ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
              onClick={() => navigate("/settings/expense")}>
              <ShoppingBagOutlinedIcon
                sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
              {
                isNavExpanded && 
                <Typography 
                  variant='body2'
                  fontWeight={600}
                  sx={{ color: currentPage === 3 ? global['purple'] : global['main_text'] }}>
                  Expense Management
                </Typography>
              }
            </Box>
            <Box className={`${currentPage === 4 ? 'nav__side-selected-admin' : 'nav__side-item-admin'}
              ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
              onClick={() => navigate("/settings/accounting")}>
              <CorporateFareOutlinedIcon 
                sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
              {
                isNavExpanded && 
                <Typography 
                  variant='body2'
                  fontWeight={600}
                  sx={{ color: currentPage === 4 ? global['purple'] : global['main_text'] }}>
                  Accounting
                </Typography>
              }
            </Box>
            <Box className={`${currentPage === 5 ? 'nav__side-selected-admin' : 'nav__side-item-admin'}
              ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
              onClick={() => navigate("/settings/international")}>
              <AccountBalanceWalletOutlinedIcon 
                sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
              {
                isNavExpanded && 
                <Typography 
                  variant='body2'
                  fontWeight={600}
                  sx={{ color: currentPage === 5 ? global['purple'] : global['main_text'] }}>
                  International
                </Typography>
              }
            </Box>
            {/* <Box className={`${currentPage === 4 ? 'nav__side-selected-admin' : 'nav__side-item-admin'} 
              ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
              onClick={() => navigate("/settings/wallet")}>
              <AccountBalanceWalletOutlinedIcon 
                sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
              {
                isNavExpanded && 
                <Typography 
                  variant='body2'
                  fontWeight={600}
                  sx={{ color: currentPage === 4 ? global['purple'] : global['main_text'] }}>
                  Wallet
                </Typography>
              }
            </Box> */}
            <Box className={`${currentPage === 6 ? 'nav__side-selected-admin' : 'nav__side-item-admin'} 
              ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
              onClick={() => navigate("/settings/billing")}>
              <ReceiptLongRoundedIcon
                sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
              {
                isNavExpanded && 
                <Typography 
                  variant='body2'
                  fontWeight={600}
                  sx={{ color: currentPage === 6 ? global['purple'] : global['main_text'] }}>
                  Billing
                </Typography>
              }
            </Box>
          </Box>
          :
          <Box
            className="flex_SBColumn"
            height={"100%"}>
            <Box
              className="flex_CenterColumn"
              gap={"20px"}>
              {/* {
                isNavExpanded
                ?
                <Button
                  variant='contained'
                  sx={{ paddingRight: "30px"}}
                  onClick={(e) => {
                    setCreateMenuAnchor(e.currentTarget);
                    setCreateMenuOpen(!createMenuOpen);
                  }}>  
                  <AddIcon />
                  Create
                </Button>
                :
                <Button
                  variant='contained'
                  sx={{ minWidth: "40px" }}
                  onClick={(e) => {
                    setCreateMenuAnchor(e.currentTarget);
                    setCreateMenuOpen(!createMenuOpen);
                  }}>  
                  <AddIcon />
                </Button>
              } */}
              <Box className="nav__side-conatiner">
                <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} 
                  ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
                  onClick={() => navigate("/")}>
                  <DashboardOutlinedIcon 
                    sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
                  {
                    isNavExpanded && 
                    <Typography 
                      variant='body2'
                      fontWeight={600}
                      sx={{ color: currentPage === 0 ? global['secondary'] : global['main_text'] }}>
                      Dashboard
                    </Typography>
                  }
                </Box>
                <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} 
                  ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
                  onClick={() => navigate("/pay")}>
                  <PaymentOutlinedIcon 
                    sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
                  {
                    isNavExpanded && 
                    <Typography 
                      variant='body2'
                      fontWeight={600}
                      sx={{ color: currentPage === 1 ? global['secondary'] : global['main_text'] }}>
                      Pay
                    </Typography>
                  }
                </Box>
                <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'}
                  ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
                  onClick={() => navigate("/get-paid")}>
                  <LocalAtmOutlinedIcon 
                    sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
                  {
                    isNavExpanded && 
                    <Typography 
                      variant='body2'
                      fontWeight={600}
                      sx={{ color: currentPage === 2 ? global['secondary'] : global['main_text'] }}>
                      {/* Receive */}
                      Get Paid
                    </Typography>
                  }
                </Box>
                <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'}
                  ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
                  onClick={() => navigate("/expense")}>
                  <ShoppingBagOutlinedIcon 
                    sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
                  {
                    isNavExpanded && 
                    <Typography 
                      variant='body2'
                      fontWeight={600}
                      sx={{  color: currentPage === 3 ? global['secondary'] : global['main_text'] }}>
                      Expense Management
                    </Typography>
                  }
                </Box>
                {/* <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'}
                  ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
                  onClick={() => navigate("/wallet")}>
                  <AccountBalanceWalletOutlinedIcon 
                    sx={{ width: isNavExpanded ? "18.5px" : "24px" }}/>
                  {
                    isNavExpanded && 
                    <Typography 
                      variant='body2'
                      fontWeight={600}
                      sx={{ color: currentPage === 4 ? global['secondary'] : global['main_text'] }}>
                      Wallet
                    </Typography>
                  }
                </Box> */}
              </Box>
            </Box>

            {
              companyRole === "Admin"
              &&
              <Box>
                <Box className="nav__side-conatiner">
                  <Box className={`nav__side-item ${isNavExpanded ? 'flexCenter_Row' : 'flexCenterCenterRow'}`}
                    sx={{ 
                      backgroundColor: global['purple_light'],
                      "&:hover": {
                        backgroundColor: "#dcd5fb",
                      } }}
                    onClick={() => navigate("/settings")}>
                    <SettingsOutlinedIcon 
                      sx={{ color: global['purple'], width: isNavExpanded ? "18.5px" : "24px" }}/>
                    {
                      isNavExpanded && 
                      <Typography 
                        variant='body2'
                        fontWeight={600}
                        sx={{ color: global['purple'] }}>
                        Settings
                      </Typography>
                    }
                  </Box>
                </Box>
              </Box>
            }
          </Box>
      }
      <Box className="nav__side-expand">
        {
          showNavExpandBtn
          &&
          <IconButton
            sx={{ backgroundColor: "#f1f1f1" }}
            onClick={() => {
              localStorage.setItem("navExpanded", !isNavExpanded);
              setIsNavExpanded(!isNavExpanded);
              setShowNavExpandBtn(false);
            }}>
            {
              isNavExpanded
              ?
              <ArrowBackIosNewIcon />
              :
              <ArrowForwardIosIcon />
            }
          </IconButton>
        }
      </Box>
    </Box>
  )
}

export default SideNavigation;