import React, { useEffect, useState, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { 
  Box, 
  Button, 
  Chip, 
  CircularProgress, 
  IconButton, 
  Link,  
  Skeleton,  
  Tooltip,  
  Typography 
} from '@mui/material';

import useSelectedCompany from '../../../../components/hooks/useSelectedCompany';
import CompanyAvatar from '../../../../components/avatars/CompanyAvatar';
import NA from '../../../../components/utils/NA';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { 
  DeleteBranch,
  GetBranches 
} from '../../../../redux/actions/main/BranchesActions';
import { 
  GetCompany 
} from '../../../../redux/actions/dashboard/companiesActions';

import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import LogoUploadModal from './LogoUploadModal';
import BranchesModal from './BranchesModal';

import global from "../../../../global.scss";
import  "./styles.scss";


const CompanyPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.main);
  const dashboard = useSelector(state => state.dashboard);
  const companyId = useSelectedCompany();
  const [loading, setLoading] = useState(false);
  const [paymentLinkCopied, setPaymentLinkCopied] = useState(false);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [logoUploadModalOpen, setLogoUploadModalOpen] = useState(false);
  const [branchesModalOpen, setBranchesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null); 

  const [companyInfo, setCompanyInfo] = useState(null);
  const [defaultBranch, setDefaultBranch] = useState(null);
  const [branchesList, setBranchesList] = useState([]);

  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    dispatch(GetCompany(companyId, setLoading));
    dispatch(GetBranches(companyId, 1, 100, setLoading));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      console.log(state);
      formatBranchesList(state.branchesList.records || []);
    } catch (err) {}
  }, [state]);

  useEffect(() => {
    try {
      console.log(dashboard);
      formatCompanyInfoObj(dashboard.companyInfo || {});
    } catch (err) {}
  }, [dashboard]);

  // ----------------- list formatter -----------------
  const formatCompanyInfoObj = (obj) => {
    setCompanyInfo(obj)
  };

  const formatBranchesList = (list) => {
    setBranchesList(list.filter(i => i.branch_type !== "head-office"));
    setDefaultBranch(list.filter(i => i.branch_type === "head-office")[0]);
  };

  const handleDelete = () => {
    setDeleteConfirmationModalOpen(false);
    dispatch(DeleteBranch(companyId, deleteId, setDeleting)).then(() => {
      dispatch(GetBranches(companyId, 1, 100, setDeleting)).then(() => {
        setDeleteId(null);
      })
    });
  };


  // --------------- logo upload ---------------
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setLogoUploadModalOpen(true); // Open the modal to crop
    }
  };

  return (
    <Box> 

      <BranchesModal
        open={branchesModalOpen}
        setOpen={setBranchesModalOpen}
        title={modalTitle}
        update={update}
        />

      <ConfirmationModal 
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        title={modalTitle}
        onCancel={() => setDeleteId(null)}
        onConfirm={handleDelete}
        />

      <LogoUploadModal 
        open={logoUploadModalOpen}
        setOpen={setLogoUploadModalOpen}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        />

      <input
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        style={{ display: 'none' }}
        id="fileInput"
      />

      {/* <Box
        className="flexFSCenterRow"
        gap="20px"> */}
        {
          loading
          ?
          <Skeleton variant="rectangular" 
            className="company__top"
            sx={{ height: "300px", borderRadius: "20px" }} />
          :
          <Box
            className="company__top">
            <Box 
              className="flexCenter_Row" 
              gap={"30px"}
              mb={"15px"}>
              <Box
                sx={{ position: "relative" }}>
                {
                  companyInfo?.logo_url
                  ?
                  <Box
                    sx={{ 
                      width: "60px", 
                      height: "60px", 
                      borderRadius: "8px" ,
                      overflow: "hidden",
                      border: "solid 1px #f1f1f1" }}>
                      <img 
                        src={companyInfo?.logo_url}
                        alt='company-logo'
                        style={{
                          width: "60px", 
                          height: "60px", 
                          objectFit: "contain"
                        }}
                        />
                  </Box>
                  :
                  <CompanyAvatar
                    size='large'
                    color={companyInfo?.color}>
                    {companyInfo?.legal_name}
                  </CompanyAvatar>
                }
                <Box
                  sx={{ 
                    position: "absolute",
                    zIndex: 100,
                    bottom: -20,
                    right: -20
                  }}>
                  <Tooltip
                    title={"Edit logo"}>
                    <IconButton
                      sx={{
                        backgroundColor: "#e1e1e1aa"
                      }}
                      onClick={() => {
                        setSelectedImage(null);
                        document.getElementById('fileInput').click()
                      }}>
                      <EditIcon sx={{ color: global['purple']}}/>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Typography
                className='longText'
                variant='h6'>
                {companyInfo?.legal_name || ""}
              </Typography>
            </Box>
            <Box
              className="flexCenter_Row" gap={"20px"}>
              <Typography minWidth={"170px"} color={"grey"}>Super admin</Typography>
              <Typography>
                {companyInfo?.user?.first_name || ""}&nbsp;
                {companyInfo?.user?.last_name || ""}&nbsp;
                <i>({companyInfo?.user?.user_name || ""})</i>
              </Typography>
            </Box>

            {
              defaultBranch !== null
              ?
              <Box
                className="flex__Column"
                gap={"20px"}>
                <Box
                  className="flexCenter_Row" gap={"20px"}>
                  <Box
                    className="flexCenter_Row" gap={"20px"}>
                    <Typography minWidth={"170px"} color={"grey"}>Tax ID type*</Typography>
                    <Typography>
                      EIN
                    </Typography>
                  </Box>
                  <Box
                    className="flexCenter_Row" gap={"20px"}
                    width={"100%"}>
                    <Typography color={"grey"}>Number*</Typography>
                    <Typography>
                      {defaultBranch?.tax_id || <NA />}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className="flexCenter_Row" gap={"20px"}>
                  <Typography minWidth={"170px"} color={"grey"}>Headoffice location*</Typography>
                  <Typography>
                    {
                      defaultBranch?.address + ", " + 
                      defaultBranch?.city + ", " +
                      defaultBranch?.state + ", " + 
                      defaultBranch?.pincode + ", " + 
                      defaultBranch?.country
                    }
                  </Typography>
                </Box>

                <Box  
                  className="flexCenterFERow"
                  gap={"15px"}>
                  <Tooltip
                    title={"Edit branch " + defaultBranch?.legal_name}>
                    <IconButton
                      onClick={() => {
                        setUpdate({
                          ...defaultBranch,
                          defaultMode: 3,
                        });
                        setModalTitle("Edit headoffice location - " + defaultBranch?.legal_name);
                        setBranchesModalOpen(!branchesModalOpen);
                      }}>
                      <EditIcon sx={{ color: global['purple']}}/>
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              :
              <Box
                className="flexCenterCenterRow"
                height={"100px"}>
                <Typography>
                  No head branch found
                </Typography>
              </Box>
            }
          </Box>
        }

        {
          loading
          ?
          <Skeleton variant="rectangular" 
            className="company__top"
            sx={{ height: "300px", borderRadius: "20px" }} />
          :
          <Box
            className="company__top"
            sx={{ backgroundColor: "#ffffff" }}>
            <Box
              className="flexCenterSBRow"
              marginBottom={"-10px"}>
              <Typography
                variant='subtitle2'>
                Other locations
              </Typography>
              <Link
                onClick={() => {
                  setUpdate({
                    defaultMode: 1
                  });
                  setModalTitle("Create location");
                  setBranchesModalOpen(!branchesModalOpen);
                }}>+ Add other location</Link>
            </Box>

            {
              branchesList.length === 0
              ?
              <Box
                className="flexCenterCenterRow"
                height={"100px"}>
                <Typography>
                  No other location found
                </Typography>
              </Box>
              :
                branchesList.map((item, index) => (
                  <Box
                    className="flex__Column company__branch"
                    gap={"20px"}
                    key={index}>
                    <Box
                      className="flexCenter_Row" gap={"20px"}>
                      <Typography minWidth={"200px"} color={"grey"}>Legal Name*</Typography>
                      <Box
                        className="flexCenter_Row" gap={"10px"}>
                        <Typography
                          variant='body1'>
                          {item?.legal_name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className="flexCenter_Row" gap={"20px"}>
                      <Box
                        className="flexCenter_Row" gap={"20px"}>
                        <Typography minWidth={"200px"} color={"grey"}>Tax ID type*</Typography>
                        <Typography>
                          EIN
                        </Typography>
                      </Box>
                      <Box
                        className="flexCenter_Row" gap={"20px"}
                        width={"100%"}>
                        <Typography color={"grey"}>Number*</Typography>
                        <Typography>
                          {defaultBranch?.tax_id ||  <NA />}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className="flexCenter_Row" gap={"20px"}>
                      <Typography minWidth={"200px"} color={"grey"}>Office location*</Typography>
                      <Typography>
                        {
                          item?.address + ", " + 
                          item?.city + ", " +
                          item?.state + ", " + 
                          item?.pincode + ", " + 
                          item?.country
                        }
                      </Typography>
                    </Box>

                    <Box  
                      className="flexCenterFERow"
                      gap={"15px"}>
                      <Tooltip
                        title={"Edit location " + item?.legal_name}>
                        <IconButton
                          onClick={() => {
                            setUpdate({
                              ...item,
                              defaultMode: 2,
                            });
                            setModalTitle("Edit location - " + item?.legal_name);
                            setBranchesModalOpen(!branchesModalOpen);
                          }}>
                          <EditIcon sx={{ color: global['purple']}}/>
                        </IconButton>
                      </Tooltip>
                      {/* {
                        item.id === deleteId && deleting
                        ?
                          <CircularProgress size={26} sx={{ margin: "7px" }}/>
                        :
                          <IconButton
                            onClick={(e) => {
                              setDeleteId(item.id);
                              setModalTitle("Delete branch " + item?.legal_name);
                              setDeleteConfirmationModalOpen(!deleteConfirmationModalOpen);
                            }}>
                            <DeleteIcon sx={{ color: global['purple']}}/>
                          </IconButton>
                      } */}
                    </Box>
                  </Box>
                ))
            }
          </Box>
        }

        
      {/* </Box> */}
    </Box>
  )
}

export default CompanyPage